import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Form, Row, Switch, Tabs } from "antd";
import AirbnbAllowChildrenInput from "apartments/components/airbnb-allow-children-input/AirbnbAllowChildrenInput";
import AirbnbAllowPetsInput from "apartments/components/airbnb-allow-pets-input/AirbnbAllowPetsInput";
import AirbnbCancelationPolicyInput from "apartments/components/airbnb-cancelation-policy-input/AirbnbCancelationPolicyInput";
import AirbnbCheckinTimeInput from "apartments/components/airbnb-checkin-time-input/AirbnbCheckinTimeInput";
import AirbnbCheckoutTimeInput from "apartments/components/airbnb-checkout-time-input/AirbnbCheckoutTimeInput";
import AirbnbDiscountInput from "apartments/components/airbnb-discount-input/AirbnbDiscountInput";
import AirbnbFeeInput from "apartments/components/airbnb-fee-input/AirbnbFeeInput";
import AirbnbGuestExpectationInput from "apartments/components/airbnb-guest-expectation-input/AirbnbGuestExpectationInput";
import AirbnbInstantBookingInput from "apartments/components/airbnb-instant-booking-input/AirbnbInstantBookingInput";
import AirbnbNonRefundableRateInput from "apartments/components/airbnb-non-refundable-rate-input/AirbnbNonRefundableRateInput";
import GuestPricingInput from "apartments/components/guest-pricing-input/GuestPricingInput";
import DeletableInputWrapper from "components/deletable-input-wrapper/DeletableInputWrapper";

export default function AirbnbSettingsForm(props) {
    const {
        form,
        currency,
        maxGuestCount,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            {...otherProps}
        >
            <Tabs
                items={[
                    {
                        label: 'Guest pricing',
                        key: 'guestPricing',
                        forceRender: true,
                        children: (
                            <>
                                <Form.Item
                                    name="guestPricing"
                                    label="Guest pricing"
                                    rules={[{
                                        validator(_, guestPricing) {
                                            if (guestPricing == null) {
                                                return Promise.reject(new Error('Guest pricing is required'));
                                            }
                                            if (guestPricing.mode == null) {
                                                return Promise.reject(new Error('Pricing mode is required'));
                                            }
                                            if (guestPricing.mode === 'simple') {
                                                if (guestPricing.pricing.baseGuestCount == null) {
                                                    return Promise.reject(new Error('Base guest price is required'));
                                                }
                                                if (guestPricing.pricing.extraGuestFee == null) {
                                                    return Promise.reject(new Error('Extra guest fee is required'));
                                                }
                                            }
                                            if (guestPricing.mode === 'complex') {
                                                if (guestPricing.pricing.some(item => item == null)) {
                                                    return Promise.reject(new Error('You need to specify all prices'));
                                                }
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <GuestPricingInput
                                        currency={currency}
                                        maxGuestCount={maxGuestCount}
                                    />
                                </Form.Item>
                            </>
                        ),
                    },
                    {
                        label: 'Fees',
                        key: 'fees',
                        forceRender: true,
                        children: (
                            <Form.List
                                name="fees"
                                rules={[{
                                    validator(_, fees) {
                                        if (fees.some((fee, index) => fees.findIndex(otherFee => otherFee?.type === fee?.type) !== index)) {
                                            return Promise.reject(new Error('Duplicated fees are not allowed'));
                                        }

                                        return Promise.resolve();
                                    },
                                }]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <Row justify="end">
                                                    <Col>
                                                        <Button
                                                            icon={<PlusOutlined />}
                                                            onClick={() => add()}
                                                        >
                                                            Add fee
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                {fields.length === 0 && (
                                                    <Empty
                                                        description="There are no fees"
                                                    />
                                                )}
                                                {fields.map(field => (
                                                    <Form.Item
                                                        {...field}
                                                        wrapperCol={{
                                                            span: 24,
                                                        }}
                                                        rules={[{
                                                            validator(_, fee) {
                                                                if (fee == null) {
                                                                    return Promise.reject(new Error('Fee is required'));
                                                                }
                                                                if (fee.type == null) {
                                                                    return Promise.reject(new Error('Type is required'));
                                                                }
                                                                if (fee.amount == null) {
                                                                    return Promise.reject(new Error('Amount is required'));
                                                                }
                                                                if (fee.unit == null) {
                                                                    return Promise.reject(new Error('Unit is required'));
                                                                }

                                                                return Promise.resolve();
                                                            },
                                                        }]}
                                                        validateTrigger="onFinish"
                                                    >
                                                        <DeletableInputWrapper
                                                            onDelete={() => remove(field.name)}
                                                        >
                                                            <AirbnbFeeInput
                                                                currency={currency}
                                                            />
                                                        </DeletableInputWrapper>
                                                    </Form.Item>
                                                ))}
                                            </Col>
                                            {errors.length > 0 && (
                                                <Col span={24}>
                                                    <Form.ErrorList
                                                        errors={errors}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                )}
                            </Form.List>
                        ),
                    },
                    {
                        label: 'Discounts',
                        key: 'discounts',
                        forceRender: true,
                        children: (
                            <Form.List
                                name="discounts"
                                rules={[{
                                    validator(_, discounts) {
                                        if (discounts.filter(discount => discount?.type === 'weeklyDiscount').length > 1) {
                                            return Promise.reject(new Error('Multiple weekly discounts not allowed'));
                                        }
                                        if (discounts.filter(discount => discount?.type === 'monthlyDiscount').length > 1) {
                                            return Promise.reject(new Error('Multiple monthly discounts not allowed'));
                                        }

                                        return Promise.resolve();
                                    }
                                }]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <Row justify="end">
                                                    <Col>
                                                        <Button
                                                            icon={<PlusOutlined />}
                                                            onClick={() => add()}
                                                        >
                                                            Add discount
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                {fields.length === 0 && (
                                                    <Empty
                                                        description="There are no discounts"
                                                    />
                                                )}
                                                {fields.map(field => (
                                                    <Form.Item
                                                        {...field}
                                                        wrapperCol={{
                                                            span: 24,
                                                        }}
                                                        rules={[{
                                                            validator(_, discount) {
                                                                if (discount == null) {
                                                                    return Promise.reject(new Error('Discount is required'));
                                                                }
                                                                if (discount.type == null) {
                                                                    return Promise.reject(new Error('Type is required'));
                                                                }
                                                                if (['lengthOfStay', 'lastMinute', 'firstMinute'].includes(discount.type)) {
                                                                    if (discount.threshold == null) {
                                                                        return Promise.reject(new Error('Threshold is required'));
                                                                    }
                                                                    if (discount.type === 'lastMinute' && discount.threshold > 28) {
                                                                        return Promise.reject(new Error('Last minute discount can be for maximum 28 days'));
                                                                    }
                                                                    if (discount.type === 'firstMinute' && !(((discount.threshold % 28) === 0) || ((discount.threshold % 30) === 0))) {
                                                                        return Promise.reject(new Error('First minute discount can be a multiple of 28 or 30 days'));
                                                                    }
                                                                }
                                                                if (discount.amount == null) {
                                                                    return Promise.reject(new Error('Discount is required'));
                                                                }

                                                                return Promise.resolve();
                                                            }
                                                        }]}
                                                        validateTrigger="onFinish"
                                                    >
                                                        <DeletableInputWrapper
                                                            onDelete={() => remove(field.name)}
                                                        >
                                                            <AirbnbDiscountInput />
                                                        </DeletableInputWrapper>
                                                    </Form.Item>
                                                ))}
                                            </Col>
                                            {errors.length > 0 && (
                                                <Col span={24}>
                                                    <Form.ErrorList
                                                        errors={errors}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                )}
                            </Form.List>
                        ),
                    },
                    {
                        label: 'Guest expectations',
                        key: 'guestExpectations',
                        forceRender: true,
                        children: (
                            <Form.List
                                name="guestExpectations"
                                rules={[{
                                    validator(_, guestExpectations) {
                                        if (guestExpectations.some((guestExpectation, index) => guestExpectations.findIndex(otherGuestExpectation => otherGuestExpectation.type === guestExpectation.type) !== index)) {
                                            return Promise.reject(new Error('Duplicated guest expectations are not allowed'));
                                        }

                                        return Promise.resolve();
                                    }
                                }]}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <Row justify="end">
                                                    <Col>
                                                        <Button
                                                            icon={<PlusOutlined />}
                                                            onClick={() => add()}
                                                        >
                                                            Add guest expectation
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                {fields.length === 0 && (
                                                    <Empty
                                                        description="There are no guest expectations"
                                                    />
                                                )}
                                                {fields.map(field => (
                                                    <Form.Item
                                                        {...field}
                                                        wrapperCol={{
                                                            span: 24,
                                                        }}
                                                        rules={[{
                                                            validator(_, guestExpectation) {
                                                                if (guestExpectation == null) {
                                                                    return Promise.reject(new Error('Guest expectation is required'));
                                                                }
                                                                if (guestExpectation.type == null) {
                                                                    return Promise.reject(new Error('Type is required'));
                                                                }

                                                                return Promise.resolve();
                                                            }
                                                        }]}
                                                    >
                                                        <DeletableInputWrapper
                                                            onDelete={() => remove(field.name)}
                                                        >
                                                            <AirbnbGuestExpectationInput />
                                                        </DeletableInputWrapper>
                                                    </Form.Item>
                                                ))}
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Form.List>
                        ),
                    },
                    {
                        label: 'Booking',
                        key: 'booking',
                        forceRender: true,
                        children: (
                            <>
                                <Form.Item
                                    name="instantBooking"
                                    label="Instant booking"
                                    rules={[{
                                        validator(_, instantBooking) {
                                            if (instantBooking == null) {
                                                return Promise.reject(new Error('Instant booking is required'));
                                            }
                                            if (instantBooking.enabled) {
                                                if (instantBooking.category == null) {
                                                    return Promise.reject(new Error('Category is required'));
                                                }
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <AirbnbInstantBookingInput />
                                </Form.Item>
                                <Form.Item
                                    name="nonRefundableRate"
                                    label="Non-refundable rate"
                                    rules={[{
                                        validator(_, nonRefundableRate) {
                                            if (nonRefundableRate == null) {
                                                return Promise.reject(new Error('Non-refundable rate is required'));
                                            }
                                            if (nonRefundableRate.enabled) {
                                                if (nonRefundableRate.discount == null) {
                                                    return Promise.reject(new Error('Discount is required'));
                                                }
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <AirbnbNonRefundableRateInput />
                                </Form.Item>
                                <Form.Item
                                    name="cancelationPolicy"
                                    label="Cancelation policy"
                                    rules={[{
                                        validator(_, cancelationPolicy) {
                                            if (cancelationPolicy == null) {
                                                return Promise.reject(new Error('Cancelation policy is required'));
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <AirbnbCancelationPolicyInput />
                                </Form.Item>
                            </>
                        ),
                    },
                    {
                        label: 'Rules',
                        key: 'rules',
                        forceRender: true,
                        children: (
                            <>
                                <Form.Item
                                    name="checkInTimeStart"
                                    label="Check-in start"
                                    rules={[{ required: true }]}
                                >
                                    <AirbnbCheckinTimeInput
                                        minHour={8}
                                        maxHour={23}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="checkInTimeEnd"
                                    label="Check-in end"
                                    rules={[
                                        { required: true },
                                        ({ getFieldValue }) => ({
                                            validator(_, checkInTimeEnd) {
                                                if (checkInTimeEnd !== 'FLEXIBLE') {
                                                    const start = parseInt(getFieldValue('checkInTimeStart'));
                                                    const end = parseInt(checkInTimeEnd);

                                                    if (start > end) {
                                                        return Promise.reject(new Error('Check-in start has to be before check-in end'));
                                                    }
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <AirbnbCheckinTimeInput
                                        minHour={9}
                                        maxHour={27}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="checkOutTimeEnd"
                                    label="Check-out end"
                                    rules={[{ required: true }]}
                                >
                                    <AirbnbCheckoutTimeInput
                                        minHour={0}
                                        maxHour={23}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="allowInfants"
                                    label="Allow infants"
                                    valuePropName="checked"
                                    rules={[{ required: true }]}
                                >
                                    <Switch />
                                </Form.Item>
                                <Form.Item
                                    name="allowChildren"
                                    label="Allow children"
                                    rules={[{
                                        validator(_, allowChildren) {
                                            if (allowChildren == null) {
                                                return Promise.reject(new Error('Allow children is required'));
                                            }
                                            if (!allowChildren.enabled) {
                                                if (allowChildren.description == null || allowChildren.description === '') {
                                                    return Promise.reject(new Error('If children are not allowed, explanation is required'));
                                                }
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <AirbnbAllowChildrenInput />
                                </Form.Item>
                                <Form.Item
                                    name="allowEvents"
                                    label="Allow events"
                                    valuePropName="checked"
                                    rules={[{ required: true }]}
                                >
                                    <Switch />
                                </Form.Item>
                                <Form.Item
                                    name="allowPets"
                                    label="Allow pets"
                                    rules={[{
                                        validator(_, allowPets) {
                                            if (allowPets == null) {
                                                return Promise.reject(new Error('Allow pets is required'));
                                            }
                                            if (allowPets.enabled) {
                                                if (allowPets.maxPetCount == null) {
                                                    return Promise.reject(new Error('If pets are allowed, capacity required'));
                                                }
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <AirbnbAllowPetsInput />
                                </Form.Item>
                                <Form.Item
                                    name="allowSmoking"
                                    label="Allow smoking"
                                    valuePropName="checked"
                                    rules={[{ required: true }]}
                                >
                                    <Switch />
                                </Form.Item>
                            </>
                        ),
                    }
                ]}
            />
        </Form>
    );
}