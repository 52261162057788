import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Col, Form, message, Modal, Row, Typography } from "antd";
import { taskPromise } from "common/task";
import { useState } from "react";
import LocationInput from "../location-input/LocationInput";
import TimezoneSelect from "components/TimezoneSelect";

const QUERY = gql`
    query GetPropertyLocationForHotelLocationOnboarding($propertyId: ID!) {
        property(propertyId: $propertyId) {
            id
            address
            latitude
            longitude
            timezone
            channels {
                id
            }
        }
    }
`;

const QUERY_CHANNEL = gql`
    query GetPropertyChannelAfterHotelLocationSettingsOnboarding($propertyChannelId: ID!) {
        propertyChannel(propertyChannelId: $propertyChannelId) {
            id
            status
            statusMessage
            roomTypeMappings {
                id
                status
                statusMessage
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdatePropertyLocation($input: UpdatePropertyLocationInput!) {
        updatePropertyLocation(input: $input) {
            error {
                type
                message
            }
            property {
                id
                address
                latitude
                longitude
                timezone
            }
            syncPropertyUpTask {
                id
            }
            syncPropertyChannelUpTasks {
                id
            }
        }
    }
`;

export default function HotelLocationOnboardingItem(props) {
    const {
        propertyId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { propertyId } });
    const [fetchPropertyChannel] = useLazyQuery(QUERY_CHANNEL);
    const [updatePropertyLocation] = useMutation(MUTATION);

    const [form] = Form.useForm();

    const onboardingDone = [
        data?.property?.address,
        data?.property?.latitude,
        data?.property?.longitude,
        data?.property?.timezone,
    ].every(item => item != null);

    async function handleSave() {
        try {
            const values = await form.validateFields();

            setLoading(true)

            const updateLocationResponse = await updatePropertyLocation({
                variables: {
                    input: {
                        propertyId,
                        address: values?.location?.address,
                        latitude: values?.location?.latitude ?? data?.property?.latitude,
                        longitude: values?.location?.longitude ?? data?.property?.longitude,
                        timezone: values?.location?.timezone,
                    },
                },
            });

            if (updateLocationResponse?.data?.updatePropertyLocation?.error) {
                message.error("Failed to update property location");
                setLoading(false);
                return;
            }

            try {
                await Promise.all([
                    taskPromise(updateLocationResponse?.data?.updatePropertyLocation?.syncPropertyUpTask?.id),
                    ...[...updateLocationResponse?.data?.updatePropertyLocation?.syncPropertyChannelUpTasks ?? []]
                        .map(task => taskPromise(task.id)),
                ]);
            }
            catch {
                message.error("Failed to update property location");
                setLoading(false);
                return;
            }

            await Promise.all(
                [...data?.property?.channels ?? []]
                    .map(channel => (
                        fetchPropertyChannel({
                            variables: {
                                propertyChannelId: channel.id,
                            },
                            fetchPolicy: 'network-only',
                        })
                    ))
            );

            message.success("Property location updated");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {onboardingDone && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!onboardingDone && (
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                Setup location
            </Col>
            <Col>
                <Typography.Link
                    onClick={() => setModalOpen(true)}
                >
                    Setup
                </Typography.Link>
            </Col>
            <Modal
                open={modalOpen}
                title="Setup location"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: loading,
                }}
                onOk={() => handleSave()}
                onCancel={() => setModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={form}
                    preserve={false}
                    initialValues={{
                        location: {
                            address: data?.property?.address,
                            latitude: data?.property?.latitude,
                            longitude: data?.property?.longitude,
                            timezone: data?.property?.timezone,
                        },
                    }}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                >
                    <Form.Item
                        name="location"
                        label="Location"
                    >
                        <LocationInput />
                    </Form.Item>
                    <Form.Item
                        name={['location', 'timezone']}
                        label="Timezone"
                    >
                        <TimezoneSelect />
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}