import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Col, Form, Input, message, Modal, Row, Select, Typography } from "antd";
import { taskPromise } from "common/task";
import { useState } from "react";
import HotelChannelOnboardingItem from "./HotelChannelOnboardingItem";

const QUERY = gql`
    query GetPropertyChannelsForHotelChannelOnboarding($propertyId: ID!) {
        property(propertyId: $propertyId) {
            id
            channels {
                id
                active
                otaName
                mapping
                otaSettings
                status
                statusMessage
                property {
                    id
                    currency
                }
            }
        }
    }
`;

const QUERY_STATUS = gql`
    query GetPropertyChannelStatus($propertyChannelId: ID!) {
        propertyChannel(propertyChannelId: $propertyChannelId) {
            id
            status
            statusMessage
            otaSettings
        }
    }
`;

const MUTATION = gql`
    mutation CreateHotelChannel($input: CreateHotelChannelInput!) {
        createHotelChannel(input: $input) {
            error {
                type
                message
            }
            propertyChannel {
                id
                otaName
                mapping
            }
            syncPropertyChannelUpTask {
                id
            }
        }
    }
`;


export default function HotelChannelsOnboardingItem(props) {
    const {
        propertyId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { propertyId } });
    const [fetchPropertyChannelStaus] = useLazyQuery(QUERY_STATUS);
    const [createHotelChannel] = useMutation(MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Property',
                    id: propertyId,
                }),
                fieldName: 'channels',
            });
        }
    });

    const [form] = Form.useForm();
    const otaName = Form.useWatch('otaName', form);

    const onboardingDone = data?.property?.channels?.length > 0;

    async function handleSave() {
        try {
            const values = await form.validateFields();

            setLoading(true)

            const createHotelChannelResponse = await createHotelChannel({
                variables: {
                    input: {
                        propertyId,
                        otaName: values.otaName,
                        mapping: values.mapping,
                    },
                },
            });

            if (createHotelChannelResponse?.data?.createHotelChannel?.error) {
                message.error("Failed to create channel");
                setLoading(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(createHotelChannelResponse?.data?.createHotelChannel?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to create channel");
                setLoading(false);
                return;
            }

            await fetchPropertyChannelStaus({
                variables: {
                    propertyChannelId: createHotelChannelResponse?.data?.createHotelChannel?.propertyChannel?.id,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status === 'success') {
                message.success("Channel created");
            }
            else {
                message.error("Failed to create channel");
            }

            setModalOpen(false);
            setLoading(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Row gutter={[16, 16]}>
                    <Col flex={0}>
                        {onboardingDone && (
                            <CheckCircleFilled
                                style={{
                                    color: green.primary,
                                }}
                            />
                        )}
                        {!onboardingDone && (
                            <HourglassOutlined
                                style={{
                                    color: grey.primary,
                                }}
                            />
                        )}
                    </Col>
                    <Col flex={1}>
                        Connect channels
                    </Col>
                    <Col>
                        <Typography.Link
                            onClick={() => setModalOpen(true)}
                        >
                            Connect
                        </Typography.Link>
                    </Col>
                    <Modal
                        open={modalOpen}
                        title="Connect channels"
                        okText="Save"
                        okButtonProps={{
                            icon: <SaveOutlined />,
                            loading: loading,
                        }}
                        onOk={() => handleSave()}
                        onCancel={() => setModalOpen(false)}
                        destroyOnClose
                        width={700}
                    >
                        <Form
                            form={form}
                            preserve={false}
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 18,
                            }}
                        >
                            <Form.Item
                                name="otaName"
                                label="Channel"
                                rules={[{ required: true, message: 'Channel is required' }]}
                            >
                                <Select
                                    options={[
                                        {
                                            label: 'Booking.com',
                                            value: 'BookingCom',
                                        },
                                        {
                                            label: 'Airbnb.com',
                                            value: 'AirBNB',
                                        },
                                    ]}
                                    style={{
                                        width: '200px',
                                    }}
                                />
                            </Form.Item>
                            {otaName === 'BookingCom' && (
                                <>
                                    <Form.Item
                                        name={['mapping', 'hotel_id']}
                                        label="Hotel ID"
                                        rules={[{ required: true, message: 'Hotel ID is required' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            )}
                            {otaName === 'AirBNB' && (
                                <Form.Item
                                    name={['mapping', 'listing_id']}
                                    label="Listing ID"
                                    rules={[{ required: true, message: 'Listing ID is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            )}
                        </Form>
                    </Modal>
                </Row>
            </Col>
            {[...data?.property?.channels ?? []].map(channel => (
                <Col
                    span={24}
                    key={channel.id}
                >
                    <HotelChannelOnboardingItem
                        propertyChannelId={channel.id}
                    />
                </Col>
            ))}
        </Row>
    );
}