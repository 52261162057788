import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Col, Form, InputNumber, message, Modal, Row, Typography } from "antd";
import { taskPromise } from "common/task";
import { useState } from "react";

const QUERY = gql`
    query GetPropertyCalendarSettingsForHotelCalendarSettingsOnboarding($propertyId: ID!) {
        property(propertyId: $propertyId) {
            id
            calendarLength
        }
    }
`;

const MUTATION = gql`
    mutation UpdateHotelCalendarSettings($input: UpdateHotelCalendarSettingsInput!) {
        updateHotelCalendarSettings(input: $input) {
            error {
                type
                message
            }
            property {
                id
                calendarLength
            }
            syncPropertyUpTask {
                id
            }
        }
    }
`;

export default function HotelCalendarOnboardingItem(props) {
    const {
        propertyId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { propertyId } });
    const [updateHotelCalendarSettings] = useMutation(MUTATION);

    const [form] = Form.useForm();

    const onboardingDone = data?.property?.calendarLength != null;

    async function handleSave() {
        try {
            const values = await form.validateFields();

            setLoading(true)

            const updateHotelCalendarSettingsResponse = await updateHotelCalendarSettings({
                variables: {
                    input: {
                        propertyId,
                        calendarLength: values.calendarLength,
                    },
                },
            });

            if (updateHotelCalendarSettingsResponse?.data?.updateHotelCalendarSettings?.error) {
                message.error("Failed to update property calendar");
                setLoading(false);
                return;
            }

            try {
                await taskPromise(updateHotelCalendarSettingsResponse?.data?.updateHotelCalendarSettings?.syncPropertyUpTask?.id);
            }
            catch {
                message.error("Failed to update property calendar");
                setLoading(false);
                return;
            }

            message.success("Property location updated");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {onboardingDone && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!onboardingDone && (
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                Setup calendar
            </Col>
            <Col>
                <Typography.Link
                    onClick={() => setModalOpen(true)}
                >
                    Setup
                </Typography.Link>
            </Col>
            <Modal
                open={modalOpen}
                title="Setup calendar"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: loading,
                }}
                onOk={() => handleSave()}
                onCancel={() => setModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={form}
                    preserve={false}
                    initialValues={{
                        calendarLength: data?.property?.calendarLength,
                    }}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                >
                    <Form.Item
                        name="calendarLength"
                        label="Calendar length"
                    >
                        <InputNumber
                            min={100}
                            max={730}
                            addonAfter="days"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}