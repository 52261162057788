import { green, grey, red } from "@ant-design/colors";
import { ApiOutlined, CheckCircleFilled, DeleteOutlined, DisconnectOutlined, ExclamationCircleFilled, HourglassOutlined, LoadingOutlined, MinusCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Col, Dropdown, Form, Input, message, Modal, Row, Tooltip, Typography } from "antd";
import { otaNameLabel } from "apartments/common";
import BookingMappingForm from "apartments/forms/BookingMappingForm";
import { taskPromise } from "common/task";
import { gql } from "graphql.macro";
import { useState } from "react";

const QUERY = gql`
    query GetPropertyChannelAndRoomTypeMappingForPropertyRoomTypeChannelOnboarding($propertyChannelId: ID!, $propertyRoomTypeId: ID!) {
        propertyChannel(propertyChannelId: $propertyChannelId) {
            id
            otaName
            active
            status
            mapping
        }
        propertyRoomType(propertyRoomTypeId: $propertyRoomTypeId) {
            id
            channelMappings {
                id
                channel {
                    id
                }
                active
                mapping
                status
                statusMessage
            }
        }
    }
`;

const QUERY_STATUS = gql`
    query GetPropertyChannelRoomTypeMappingStatus($propertyChannelRoomTypeMappingId: ID!) {
        propertyChannelRoomTypeMapping(propertyChannelRoomTypeMappingId: $propertyChannelRoomTypeMappingId) {
            id
            active
            status
            statusMessage
        }
    }
`;

const MUTATION_CREATE = gql`
    mutation CreatePropertyChannelRoomTypeMapping($input: CreatePropertyChannelRoomTypeMappingInput!) {
        createPropertyChannelRoomTypeMapping(input: $input) {
            error {
                type
                message
            }
            propertyChannelRoomTypeMapping {
                id
            }
            syncPropertyChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_UPDATE = gql`
    mutation UpdatePropertyChannelRoomTypeMapping($input: UpdatePropertyChannelRoomTypeMappingInput!) {
        updatePropertyChannelRoomTypeMapping(input: $input) {
            error {
                type
                message
            }
            syncPropertyChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_CONNECT = gql`
    mutation ConnectPropertyChannelRoomTypeMapping($input: ConnectPropertyChannelRoomTypeMappingInput!) {
        connectPropertyChannelRoomTypeMapping(input: $input) {
            error {
                type
                message
            }
            syncPropertyChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_DISCONNECT = gql`
    mutation DisconnectPropertyChannelRoomTypeMapping($input: DisconnectPropertyChannelRoomTypeMappingInput!) {
        disconnectPropertyChannelRoomTypeMapping(input: $input) {
            error {
                type
                message
            }
            syncPropertyChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_DELETE = gql`
    mutation DeletePropertyChannelRoomTypeMapping($input: DeletePropertyChannelRoomTypeMappingInput!) {
        deletePropertyChannelRoomTypeMapping(input: $input) {
            error {
                type
                message
            }
        }
    }
`;


export default function HotelRoomTypeChannelOnboardingItem(props) {
    const {
        propertyChannelId,
        propertyRoomTypeId,
    } = props;

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [connectModalOpen, setConnectModalOpen] = useState(false);
    const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [disconnectAndDeleteModalOpen, setDisconnectAndDeleteModalOpen] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [connectLoading, setConnectLoading] = useState(false);
    const [disconnectLoading, setDisconnectLoading] = useState(false);
    const [disconnectAndDeleteLoading, setDisconnectAndDeleteLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { propertyChannelId, propertyRoomTypeId } });
    const [fetchPropertyChannelRoomTypeMappingStaus] = useLazyQuery(QUERY_STATUS);
    const [createPropertyChannelRoomTypeMapping] = useMutation(MUTATION_CREATE, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'PropertyRoomType',
                    id: propertyRoomTypeId,
                }),
                fieldName: 'channelMappings',
            });
        },
    });
    const [updatePropertyChannelRoomTypeMapping] = useMutation(MUTATION_UPDATE);
    const [connectPropertyChannelRoomTypeMapping] = useMutation(MUTATION_CONNECT);
    const [disconnectPropertyChannelRoomTypeMapping] = useMutation(MUTATION_DISCONNECT);
    const [deletePropertyChannelRoomTypeMapping] = useMutation(MUTATION_DELETE, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'PropertyRoomType',
                    id: propertyRoomTypeId,
                }),
                fieldName: 'channelMappings',
            });
        },
    })

    const [createForm] = Form.useForm();
    const [updateForm] = Form.useForm();

    const propertyChannelRoomTypeMapping = [...data?.propertyRoomType?.channelMappings ?? []].find(mapping => mapping.channel.id === propertyChannelId);
    const onboardingDone = !!propertyChannelRoomTypeMapping;

    function handleAction(action) {
        switch (action) {
            case 'edit':
                setUpdateModalOpen(true);
                break;
            case 'connect':
                setConnectModalOpen(true);
                break;
            case 'disconnect':
                setDisconnectModalOpen(true);
                break;
            case 'delete':
                setDeleteModalOpen(true);
                break;
            case 'disconnectAndDelete':
                setDisconnectAndDeleteModalOpen(true);
                break;
            default:
        }
    }

    async function handleCreate() {
        try {
            const values = await createForm.validateFields();

            setCreateLoading(true)

            const createPropertyChannelRoomTypeMappingResponse = await createPropertyChannelRoomTypeMapping({
                variables: {
                    input: {
                        propertyChannelId,
                        propertyRoomTypeId,
                        mapping: values.mapping,
                    },
                },
            });

            if (createPropertyChannelRoomTypeMappingResponse?.data?.createPropertyChannelRoomTypeMapping?.error) {
                message.error("Failed to create mapping");
                setCreateLoading(false);
                setCreateModalOpen(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(createPropertyChannelRoomTypeMappingResponse?.data?.createPropertyChannelRoomTypeMapping?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to create mapping");
                setCreateLoading(false);
                setCreateModalOpen(false);
                return;
            }

            await fetchPropertyChannelRoomTypeMappingStaus({
                variables: {
                    propertyChannelRoomTypeMappingId: createPropertyChannelRoomTypeMappingResponse?.data?.createPropertyChannelRoomTypeMapping?.propertyChannelRoomTypeMapping?.id,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status === 'success') {
                message.success("Mapping created");
            }
            else {
                message.error("Failed to create mapping");
            }

            setCreateLoading(false);
            setCreateModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setCreateLoading(false);
        }
    }

    async function handleUpdate() {
        try {
            const values = await updateForm.validateFields();

            setUpdateLoading(true)

            const updatePropertyChannelRoomTypeMappingResponse = await updatePropertyChannelRoomTypeMapping({
                variables: {
                    input: {
                        propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                        mapping: values.mapping,
                    },
                },
            });

            if (updatePropertyChannelRoomTypeMappingResponse?.data?.updatePropertyChannelRoomTypeMapping?.error) {
                message.error("Failed to update mapping");
                setUpdateLoading(false);
                setUpdateModalOpen(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(updatePropertyChannelRoomTypeMappingResponse?.data?.updatePropertyChannelRoomTypeMapping?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to update mapping");
                setUpdateLoading(false);
                setUpdateModalOpen(false);
                return;
            }

            await fetchPropertyChannelRoomTypeMappingStaus({
                variables: {
                    propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status === 'success') {
                message.success("Mapping updated");
            }
            else {
                message.error("Failed to update mapping");
            }

            setUpdateLoading(false);
            setUpdateModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateLoading(false);
        }
    }

    async function handleConnect() {
        try {
            setConnectLoading(true)

            const connectPropertyChannelRoomTypeMappingResponse = await connectPropertyChannelRoomTypeMapping({
                variables: {
                    input: {
                        propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                    },
                },
            });

            if (connectPropertyChannelRoomTypeMappingResponse?.data?.connectPropertyChannelRoomTypeMapping?.error) {
                message.error("Failed to connect mapping");
                setConnectLoading(false);
                setConnectModalOpen(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(connectPropertyChannelRoomTypeMappingResponse?.data?.connectPropertyChannelRoomTypeMapping?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to connect mapping");
                setConnectLoading(false);
                setConnectModalOpen(false);
                return;
            }

            await fetchPropertyChannelRoomTypeMappingStaus({
                variables: {
                    propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status === 'success') {
                message.success("Mapping connected");
            }
            else {
                message.error("Failed to connect mapping");
            }

            setConnectLoading(false);
            setConnectModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setConnectLoading(false);
        }
    }

    async function handleDisconnect() {
        try {
            setDisconnectLoading(true)

            const disconnectPropertyChannelRoomTypeMappingResponse = await disconnectPropertyChannelRoomTypeMapping({
                variables: {
                    input: {
                        propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                    },
                },
            });

            if (disconnectPropertyChannelRoomTypeMappingResponse?.data?.disconnectPropertyChannelRoomTypeMapping?.error) {
                message.error("Failed to disconnect mapping");
                setDisconnectLoading(false);
                setDisconnectModalOpen(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(disconnectPropertyChannelRoomTypeMappingResponse?.data?.disconnectPropertyChannelRoomTypeMapping?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to disconnect mapping");
                setDisconnectLoading(false);
                setDisconnectModalOpen(false);
                return;
            }

            await fetchPropertyChannelRoomTypeMappingStaus({
                variables: {
                    propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status === 'success') {
                message.success("Mapping disconnected");
            }
            else {
                message.error("Failed to disconnect mapping");
            }

            setDisconnectLoading(false);
            setDisconnectModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setDisconnectLoading(false);
        }
    }

    async function handleDelete() {
        try {
            setDeleteLoading(true)

            const deletePropertyChannelRoomTypeMappingResponse = await deletePropertyChannelRoomTypeMapping({
                variables: {
                    input: {
                        propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                    },
                },
            });

            if (deletePropertyChannelRoomTypeMappingResponse?.data?.deletePropertyChannelRoomTypeMapping?.error) {
                message.error("Failed to delete mapping");
                setDeleteLoading(false);
                setDeleteModalOpen(false);
                return;
            }

            message.success("Mapping deleted");
            setDeleteLoading(false);
            setDeleteModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setDeleteLoading(false);
        }
    }

    async function handleDisconnectAndDelete() {
        try {
            setDisconnectAndDeleteLoading(true)

            const disconnectPropertyChannelRoomTypeMappingResponse = await disconnectPropertyChannelRoomTypeMapping({
                variables: {
                    input: {
                        propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                    },
                },
            });

            if (disconnectPropertyChannelRoomTypeMappingResponse?.data?.disconnectPropertyChannelRoomTypeMapping?.error) {
                message.error("Failed to delete mapping");
                setDisconnectAndDeleteLoading(false);
                setDisconnectAndDeleteModalOpen(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(disconnectPropertyChannelRoomTypeMappingResponse?.data?.disconnectPropertyChannelRoomTypeMapping?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to delete mapping");
                setDisconnectAndDeleteLoading(false);
                setDisconnectAndDeleteModalOpen(false);
                return;
            }

            await fetchPropertyChannelRoomTypeMappingStaus({
                variables: {
                    propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status !== 'success') {
                message.error("Failed to disconnect mapping");
                setDisconnectAndDeleteLoading(false);
                setDisconnectAndDeleteModalOpen(false);
                return;
            }

            const deletePropertyChannelRoomTypeMappingResponse = await deletePropertyChannelRoomTypeMapping({
                variables: {
                    input: {
                        propertyChannelRoomTypeMappingId: propertyChannelRoomTypeMapping?.id,
                    },
                },
            });

            if (deletePropertyChannelRoomTypeMappingResponse?.data?.deletePropertyChannelRoomTypeMapping?.error) {
                message.error("Failed to delete mapping");
                setDisconnectAndDeleteLoading(false);
                setDisconnectAndDeleteModalOpen(false);
                return;
            }

            message.success("Mapping deleted");
            setDisconnectAndDeleteLoading(false);
            setDisconnectAndDeleteModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setDisconnectAndDeleteLoading(false);
        }
    }

    if (!onboardingDone) {
        return (
            <Row gutter={[16, 16]}>
                <Col flex={0}>
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                </Col>
                <Col flex={1}>
                    Connect {otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)}
                </Col>
                <Col flex={0}>
                    <Typography.Link
                        onClick={() => setCreateModalOpen(true)}
                    >
                        Connect
                    </Typography.Link>
                </Col>
                <Modal
                    open={createModalOpen}
                    title={`Connect ${otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)}`}
                    okText="Connect"
                    okButtonProps={{
                        icon: <ApiOutlined />,
                        loading: createLoading,
                    }}
                    onOk={() => handleCreate()}
                    onCancel={() => setCreateModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    {data?.propertyChannel?.otaName === 'BookingCom' && (
                        <BookingMappingForm
                            form={createForm}
                            propertyChannelId={propertyChannelId}
                        />
                    )}
                    {data?.propertyChannel?.otaName === 'AirBNB' && (
                        <Form
                            form={createForm}
                            preserve={false}
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 18,
                            }}
                        >
                            <Form.Item
                                name={['mapping', 'listing_id']}
                                label="Listing ID"
                                rules={[{ required: true, message: 'Listing ID is required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    )}
                </Modal>
            </Row>
        );
    }
    else {
        return (
            <Row gutter={[16, 16]}>
                <Col flex={0}>
                    {(createLoading || updateLoading) && (
                        <LoadingOutlined />
                    )}
                    {!(createLoading || updateLoading) && propertyChannelRoomTypeMapping.active && propertyChannelRoomTypeMapping?.status === 'success' && (
                        <CheckCircleFilled
                            style={{
                                color: green.primary,
                            }}
                        />
                    )}
                    {!(createLoading || updateLoading) && propertyChannelRoomTypeMapping.active && propertyChannelRoomTypeMapping?.status !== 'success' && (
                        <ExclamationCircleFilled
                            style={{
                                color: red.primary,
                            }}
                        />
                    )}
                    {!(createLoading || updateLoading) && !propertyChannelRoomTypeMapping.active && (
                        <MinusCircleOutlined
                            style={{
                                color: grey.primary,
                            }}
                        />
                    )}
                </Col>
                <Col flex={1}>
                    {propertyChannelRoomTypeMapping?.status === 'success' && propertyChannelRoomTypeMapping.active && (
                        <Typography.Text>
                            {otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)} connected
                        </Typography.Text>
                    )}
                    {propertyChannelRoomTypeMapping?.status === 'success' && !propertyChannelRoomTypeMapping.active && (
                        <Typography.Text>
                            {otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)} disconnected
                        </Typography.Text>
                    )}
                    {propertyChannelRoomTypeMapping?.status !== 'success' && (
                        <Tooltip title={propertyChannelRoomTypeMapping.statusMessage}>
                            {otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)} error
                        </Tooltip>
                    )}
                </Col>
                <Col flex={0}>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: 'Edit',
                                    key: 'edit',
                                },
                                ...['occupancy_mismatch', 'unknown_error'].includes(propertyChannelRoomTypeMapping?.status) ? [
                                    {
                                        label: 'Reconnect',
                                        key: 'connect',
                                    },
                                ] : [],
                                ...propertyChannelRoomTypeMapping?.status === 'success' && propertyChannelRoomTypeMapping?.active ? [
                                    {
                                        label: 'Disconnect',
                                        key: 'disconnect',
                                        danger: true,
                                    },
                                ] : [],
                                ...propertyChannelRoomTypeMapping?.status === 'success' && !propertyChannelRoomTypeMapping?.active ? [
                                    {
                                        label: 'Connect',
                                        key: 'connect',
                                    },
                                    {
                                        label: 'Delete',
                                        key: 'delete',
                                        danger: true,
                                    },
                                ] : [],
                                ...propertyChannelRoomTypeMapping?.status !== 'success' && !propertyChannelRoomTypeMapping?.active ? [
                                    {
                                        label: 'Delete',
                                        key: 'disconnectAndDelete',
                                        danger: true,
                                    },
                                ] : [],
                            ],
                            onClick: ({ key }) => handleAction(key),
                        }}
                        trigger={['click']}
                    >
                        <Typography.Link>
                            Options
                        </Typography.Link>
                    </Dropdown>
                </Col>
                <Modal
                    open={updateModalOpen}
                    title={`Edit ${otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)}`}
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: updateLoading,
                    }}
                    onOk={() => handleUpdate()}
                    onCancel={() => setUpdateModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <Row gutter={[16, 16]}>
                        {propertyChannelRoomTypeMapping?.statusMessage && (
                            <Col span={24}>
                                <Alert
                                    type="error"
                                    showIcon
                                    message={propertyChannelRoomTypeMapping?.statusMessage}
                                />
                            </Col>
                        )}
                        <Col span={24}>
                            {data?.propertyChannel?.otaName === 'BookingCom' && (
                                <BookingMappingForm
                                    form={updateForm}
                                    preserve={false}
                                    initialValues={{
                                        mapping: propertyChannelRoomTypeMapping?.mapping,
                                    }}
                                    propertyChannelId={propertyChannelId}
                                />
                            )}
                            {data?.propertyChannel?.otaName === 'AirBNB' && (
                                <Form
                                    form={updateForm}
                                    preserve={false}
                                    initialValues={{
                                        mapping: propertyChannelRoomTypeMapping?.mapping,
                                    }}
                                    labelCol={{
                                        span: 6,
                                    }}
                                    wrapperCol={{
                                        span: 18,
                                    }}
                                >
                                    <Form.Item
                                        name={['mapping', 'listing_id']}
                                        label="Listing ID"
                                        rules={[{ required: true, message: 'Listing ID is required' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Form>
                            )}
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    open={connectModalOpen}
                    title="Connect mapping"
                    okText="Connect"
                    okButtonProps={{
                        icon: <ApiOutlined />,
                        loading: connectLoading,
                    }}
                    onOk={() => handleConnect()}
                    onCancel={() => setConnectModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    Are you sure you want to connect this mapping?
                </Modal>
                <Modal
                    open={disconnectModalOpen}
                    title="Disconnect mapping"
                    okText="Disconnect"
                    okButtonProps={{
                        icon: <DisconnectOutlined />,
                        loading: disconnectLoading,
                        danger: true,
                    }}
                    onOk={() => handleDisconnect()}
                    onCancel={() => setDisconnectModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    Are you sure you want to disconnect this mapping?
                </Modal>
                <Modal
                    open={deleteModalOpen}
                    title="Delete mapping"
                    okText="Delete"
                    okButtonProps={{
                        icon: <DeleteOutlined />,
                        loading: deleteLoading,
                        danger: true,
                    }}
                    onOk={() => handleDelete()}
                    onCancel={() => setDeleteModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    Are you sure you want to delete this mapping?
                </Modal>

                <Modal
                    open={disconnectAndDeleteModalOpen}
                    title="Delete mapping"
                    okText="Delete"
                    okButtonProps={{
                        icon: <DeleteOutlined />,
                        loading: disconnectAndDeleteLoading,
                        danger: true,
                    }}
                    onOk={() => handleDisconnectAndDelete()}
                    onCancel={() => setDisconnectAndDeleteModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    Are you sure you want to delete this mapping?
                </Modal>
            </Row>
        );
    }
}