import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Card, Col, Form, Input, message, Modal, Row, Typography } from "antd";
import HotelTagsOnboardingItem from "./HotelTagsOnboardingItem";
import HotelLocationOnboardingItem from "./HotelLocationOnboardingItem";
import HotelCalendarOnboardingItem from "./HotelCalendarOnboardingItem";
import HotelAvailabilityOnboardingItem from "./HotelAvailabilityOnboardingItem";
import HotelPricingOnboardingItem from "./HotelPricingOnboardingItem";
import HotelRoomTypeOnboarding from "./HotelRoomTypeOnboarding";
import { useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { taskPromise } from "common/task";
import HotelChannelsOnboardingItem from "./HotelChannelsOnboardingItem";

const QUERY = gql`
    query GetHotelForOnboarding($propertyId: ID!) {
        property(propertyId: $propertyId) {
            id
            tags
            address
            latitude
            longitude
            timezone
            calendarLength
            cutOffTime
            currency
            channels {
                id
                active
                otaName
                mapping
                otaSettings
                status
                statusMessage
                property {
                    id
                    currency
                }
            }
            roomTypes {
                id
                name
                maxGuestCount
                weekdayMinStay
                defaultPrice
                rooms {
                    id
                    name
                    active
                    activeToday
                    activeFrom
                    inactiveFrom
                }
                property {
                    id
                    currency
                    channels {
                        id
                        active
                        status
                    }
                }
                channelMappings {
                    id
                    channel {
                        id
                    }
                    active
                    mapping
                    status
                    statusMessage
                }
            }
        }
    }
`;

const MUTATION = gql`
    mutation CreateHotelRoomType($input: CreateHotelRoomTypeInput!) {
        createHotelRoomType(input: $input) {
            error {
                type
                message
            }
            syncPropertyUpTask {
                id
            }
        }
    }
`;

export default function HotelOnboarding(props) {
    const { propertyId } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data, error } = useQuery(QUERY, { variables: { propertyId } });
    const [createHotelRoomType] = useMutation(MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Property',
                    id: propertyId,
                }),
                fieldName: 'roomTypes',
            });
        }
    });

    const [form] = Form.useForm();

    async function handleCreateRoomType() {
        try {
            const values = await form.validateFields();

            setLoading(true)

            const createHotelRoomTypeResponse = await createHotelRoomType({
                variables: {
                    input: {
                        propertyId,
                        name: values.name,
                    },
                },
            });

            if (createHotelRoomTypeResponse?.data?.createHotelRoomType?.error) {
                message.error("Failed to create room type");
                setLoading(false);
                return;
            }

            try {
                await taskPromise(createHotelRoomTypeResponse?.data?.createHotelRoomType?.syncPropertyUpTask?.id);
            }
            catch {
                message.error("Failed to create room type");
                setLoading(false);
                return;
            }

            message.success("Room type created");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    if (!data) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load property"
            />
        );
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <HotelTagsOnboardingItem
                                propertyId={propertyId}
                            />
                        </Col>
                        <Col span={24}>
                            <HotelLocationOnboardingItem
                                propertyId={propertyId}
                            />
                        </Col>
                        <Col span={24}>
                            <HotelCalendarOnboardingItem
                                propertyId={propertyId}
                            />
                        </Col>
                        <Col span={24}>
                            <HotelAvailabilityOnboardingItem
                                propertyId={propertyId}
                            />
                        </Col>
                        <Col span={24}>
                            <HotelPricingOnboardingItem
                                propertyId={propertyId}
                            />
                        </Col>
                        <Col span={24}>
                            <HotelChannelsOnboardingItem
                                propertyId={propertyId}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
            {[...data?.property?.roomTypes ?? []]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(roomType => (
                    <Col
                        span={24}
                        key={roomType.id}
                    >
                        <HotelRoomTypeOnboarding
                            propertyRoomTypeId={roomType.id}
                        />
                    </Col>
                ))}
            <Col span={24}>
                <Row justify="center">
                    <Col>
                        <Typography.Link
                            onClick={() => setModalOpen(true)}
                        >
                            Add room type
                        </Typography.Link>
                        <Modal
                            open={modalOpen}
                            title="Add room type"
                            okText="Add"
                            okButtonProps={{
                                icon: <SaveOutlined />,
                                loading: loading,
                            }}
                            onOk={() => handleCreateRoomType()}
                            onCancel={() => setModalOpen(false)}
                            destroyOnClose
                            width={700}
                        >
                            <Form
                                form={form}
                                preserve={false}
                                labelCol={{
                                    span: 6,
                                }}
                                wrapperCol={{
                                    span: 12,
                                }}
                            >
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[{ required: true, message: 'Name is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}