import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Col, Form, InputNumber, message, Modal, Row, Typography } from "antd";
import { taskPromise } from "common/task";
import { useState } from "react";

const QUERY = gql`
    query GetPropertyRoomTypeOccupancySettingsForHotelRoomTypeOccupancySettingsOnboarding($propertyRoomTypeId: ID!) {
        propertyRoomType(propertyRoomTypeId: $propertyRoomTypeId) {
            id
            maxGuestCount
            property {
                id
                channels {
                    id
                }
            }
        }
    }
`;

const QUERY_CHANNEL = gql`
    query GetPropertyChannelAfterHotelRoomTypeOccupancySettingsOnboarding($propertyChannelId: ID!) {
        propertyChannel(propertyChannelId: $propertyChannelId) {
            id
            status
            statusMessage
            roomTypeMappings {
                id
                status
                statusMessage
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdateHotelRoomTypeOccupancySettings($input: UpdateHotelRoomTypeOccupancySettingsInput!) {
        updateHotelRoomTypeOccupancySettings(input: $input) {
            error {
                type
                message
            }
            propertyRoomType {
                id
                maxGuestCount
            }
            syncPropertyUpTask {
                id
            }
            syncPropertyChannelUpTasks {
                id
            }
        }
    }
`;

export default function HotelRoomTypeOccupancyOnboardingItem(props) {
    const {
        propertyRoomTypeId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { propertyRoomTypeId } });
    const [fetchPropertyChannel] = useLazyQuery(QUERY_CHANNEL);
    const [updateHotelRoomTypeOccupancySettings] = useMutation(MUTATION);

    const [form] = Form.useForm();

    const onboardingDone = data?.propertyRoomType?.maxGuestCount != null;

    async function handleSave() {
        try {
            const values = await form.validateFields();

            setLoading(true)

            const updateHotelRoomTypeOccupancySettingsResponse = await updateHotelRoomTypeOccupancySettings({
                variables: {
                    input: {
                        propertyRoomTypeId,
                        maxGuestCount: values.maxGuestCount,
                    },
                },
            });

            if (updateHotelRoomTypeOccupancySettingsResponse?.data?.updateHotelRoomTypeOccupancySettings?.error) {
                message.error("Failed to update room type occupancy");
                setLoading(false);
                return;
            }

            try {
                await Promise.all([
                    taskPromise(updateHotelRoomTypeOccupancySettingsResponse?.data?.updateHotelRoomTypeOccupancySettings?.syncPropertyUpTask?.id),
                    ...[...updateHotelRoomTypeOccupancySettingsResponse?.data?.updateHotelRoomTypeOccupancySettings?.syncPropertyChannelUpTasks ?? []]
                        .map(task => taskPromise(task.id)),
                ]);
            }
            catch {
                message.error("Failed to update room type occupancy");
                setLoading(false);
                return;
            }

            await Promise.all(
                [...data?.propertyRoomType?.property?.channels ?? []]
                    .map(channel => (
                        fetchPropertyChannel({
                            variables: {
                                propertyChannelId: channel.id,
                            },
                            fetchPolicy: 'network-only',
                        })
                    ))
            );

            message.success("Room type occupancy updated");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {onboardingDone && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!onboardingDone && (
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                Setup occupancy
            </Col>
            <Col>
                <Typography.Link
                    onClick={() => setModalOpen(true)}
                >
                    Setup
                </Typography.Link>
            </Col>
            <Modal
                open={modalOpen}
                title="Setup occupancy"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: loading,
                }}
                onOk={() => handleSave()}
                onCancel={() => setModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={form}
                    preserve={false}
                    initialValues={{
                        maxGuestCount: data?.propertyRoomType?.maxGuestCount,
                    }}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                >
                    <Form.Item
                        name="maxGuestCount"
                        label="Max occupancy"
                    >
                        <InputNumber
                            min={1}
                            max={15}
                            addonAfter="guests"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}