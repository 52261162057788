import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import MinimumStayInput from "../minimum-stay-input/MinimumStayInput";
import { Col, Form, message, Modal, Row, Typography } from "antd";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { green, grey } from "@ant-design/colors";
import { taskPromise } from "common/task";
import { useState } from "react";

const QUERY = gql`
    query GetPropertyRoomTypeAvailabilitySettingsForHotelRoomTypeAvailabilitySettingsOnboarding($propertyRoomTypeId: ID!) {
        propertyRoomType(propertyRoomTypeId: $propertyRoomTypeId) {
            id
            weekdayMinStay
        }
    }
`;

const MUTATION = gql`
    mutation UpdateHotelRoomTypeAvailabilitySettings($input: UpdateHotelRoomTypeAvailabilitySettingsInput!) {
        updateHotelRoomTypeAvailabilitySettings(input: $input) {
            error {
                type
                message
            }
            propertyRoomType {
                id
                weekdayMinStay
            }
            syncPropertyUpTask {
                id
            }
        }
    }
`;

export default function HotelRoomTypeAvailabilityOnboardingItem(props) {
    const {
        propertyRoomTypeId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { propertyRoomTypeId } });
    const [updateHotelRoomTypeAvailabilitySettings] = useMutation(MUTATION);

    const [form] = Form.useForm();

    const onboardingDone = data?.propertyRoomType?.weekdayMinStay != null;

    async function handleSave() {
        try {
            const values = await form.validateFields();

            setLoading(true)

            const updateHotelRoomTypeAvailabilitySettingsResponse = await updateHotelRoomTypeAvailabilitySettings({
                variables: {
                    input: {
                        propertyRoomTypeId,
                        weekdayMinStay: values.weekdayMinStay,
                    },
                },
            });

            if (updateHotelRoomTypeAvailabilitySettingsResponse?.data?.updateHotelRoomTypeAvailabilitySettings?.error) {
                message.error("Failed to update room type availability");
                setLoading(false);
                return;
            }

            try {
                await taskPromise(updateHotelRoomTypeAvailabilitySettingsResponse?.data?.updateHotelRoomTypeAvailabilitySettings?.syncPropertyUpTask?.id);
            }
            catch {
                message.error("Failed to update room type availability");
                setLoading(false);
                return;
            }

            message.success("Room type availability updated");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {onboardingDone && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!onboardingDone && (
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                Setup availability
            </Col>
            <Col>
                <Typography.Link
                    onClick={() => setModalOpen(true)}
                >
                    Setup
                </Typography.Link>
            </Col>
            <Modal
                open={modalOpen}
                title="Setup availability"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: loading,
                }}
                onOk={() => handleSave()}
                onCancel={() => setModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={form}
                    preserve={false}
                    initialValues={{
                        weekdayMinStay: data?.propertyRoomType?.weekdayMinStay,
                    }}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                >
                    <Form.Item
                        name="weekdayMinStay"
                        label="Minimum stay"
                    >
                        <MinimumStayInput />
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}