import { PlusOutlined } from "@ant-design/icons";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Button, Card, Col, Form, Input, message, PageHeader, Row } from "antd";
import { taskPromise } from "common/task";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const QUERY = gql`
    query GetApartmentsForCreateApartment {
        apartments {
            id
            name
            active
        }
    }
`;

const MUTATION = gql`
    mutation CreateApartment($input: CreateApartmentInput!) {
        createApartment(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
            }
            syncApartmentTask {
                id
            }
        }
    }
`;

export default function CreateApartmentView() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [getApartments] = useLazyQuery(QUERY);
    const [createApartment] = useMutation(MUTATION);

    const [form] = Form.useForm();

    function handleCreateApartment() {
        form
            .validateFields()
            .then(values => {
                setLoading(true);
                createApartment({
                    variables: {
                        input: {
                            name: values.name,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.createApartment.error) {
                            message.error("Failed to create apartment");
                            setLoading(false);
                        }
                        else {
                            taskPromise(response.data.createApartment.syncApartmentTask.id)
                                .then(() => {
                                    message.success("Apartment created");
                                    navigate(`/apartments/${response.data.createApartment.apartment.id}/onboarding`);
                                    setLoading(false);
                                });
                        }
                    });
            });
    }

    function validateName(name) {
        return getApartments()
            .then(response => {
                const otherApartment = response.data.apartments.find(apartment => apartment.name === name);
                if (otherApartment) {
                    if (otherApartment.active) {
                        return Promise.reject(new Error("Apartment already exists"));
                    }
                    else {
                        return Promise.reject(new Error("Apartment already exists but it's inactive"));
                    }
                }
                else {
                    return Promise.resolve();
                }
            });
    }

    return (
        <PageHeader
            title="Create apartment"
            onBack={() => navigate(-1)}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form
                            form={form}
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 12,
                            }}
                        >
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    { required: true, message: 'Name is required' },
                                    {
                                        validator(_, name) {
                                            return validateName(name);
                                        },
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Button
                                type="primary"
                                onClick={() => handleCreateApartment()}
                                icon={<PlusOutlined />}
                                loading={loading}
                            >
                                Create
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    );
}