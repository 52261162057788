import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, SaveOutlined, StopOutlined } from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Col, Dropdown, Form, Input, message, Modal, Row, Skeleton, Typography } from "antd";
import { taskPromise } from "common/task";
import DatePicker from "components/DatePicker";
import { formatISO } from "date-fns";
import { useState } from "react";

const QUERY = gql`
    query GetPropertyRoomForHotelRoomOnboardin($propertyRoomId: ID!) {
        propertyRoom(propertyRoomId: $propertyRoomId) {
            id
            name
            active
            activeToday
            activeFrom
            inactiveFrom
        }
    }
`;

const MUTATION_UPDATE_NAME = gql`
    mutation UpdateHotelRoomName($input: UpdateHotelRoomNameInput!) {
        updateHotelRoomName(input: $input) {
            error {
                type
                message
            }
            propertyRoom {
                id
                name
            }
        }
    }
`;

const MUTATION_OPEN = gql`
    mutation OpenHotelRoom($input: OpenHotelRoomInput!) {
        openHotelRoom(input: $input) {
            error {
                type
                message
            }
            propertyRoom {
                id
                active
                activeToday
                activeFrom
                inactiveFrom
            }
            syncPropertyRoomTypeCalendarUpTask {
                id
            }
        }
    }
`;

const MUTATION_CLOSE = gql`
    mutation OpenHotelRoom($input: OpenHotelRoomInput!) {
        closeHotelRoom(input: $input) {
            error {
                type
                message
            }
            propertyRoom {
                id
                active
                activeToday
                activeFrom
                inactiveFrom
            }
            syncPropertyRoomTypeCalendarUpTask {
                id
            }
        }
    }
`;

export default function HotelRoomOnboarding(props) {
    const {
        propertyRoomId,
    } = props;

    const [updateRoomNameModalOpen, setUpdateRoomNameModalOpen] = useState(false);
    const [openRoomModalOpen, setOpenRoomModalOpen] = useState(false);
    const [closeRoomModalOpen, setCloseRoomModalOpen] = useState(false);
    const [updateRoomNameLoading, setUpdateRoomNameLoading] = useState(false);
    const [openRoomLoading, setOpenRoomLoading] = useState(false);
    const [closeRoomLoading, setCloseRoomLoading] = useState(false);

    const { data, loading, error } = useQuery(QUERY, { variables: { propertyRoomId } });
    const [updateRoomName] = useMutation(MUTATION_UPDATE_NAME);
    const [openRoom] = useMutation(MUTATION_OPEN);
    const [closeRoom] = useMutation(MUTATION_CLOSE);

    const [updateRoomNameForm] = Form.useForm();
    const [openRoomForm] = Form.useForm();
    const [closeRoomForm] = Form.useForm();

    function handleAction(action) {
        switch (action) {
            case 'edit':
                setUpdateRoomNameModalOpen(true);
                break;
            case 'close':
                setCloseRoomModalOpen(true);
                break;
            case 'open':
                setOpenRoomModalOpen(true);
                break;
            default:
        }
    }

    async function handleUpdateRoomName() {
        try {
            const values = await updateRoomNameForm.validateFields();

            setUpdateRoomNameLoading(true)

            const updateRoomNameResponse = await updateRoomName({
                variables: {
                    input: {
                        propertyRoomId,
                        name: values.name,
                    },
                },
            });

            if (updateRoomNameResponse?.data?.updateHotelRoomName?.error) {
                message.error("Failed to update room name");
                setUpdateRoomNameLoading(false);
                return;
            }

            message.success("Room name updated");
            setUpdateRoomNameLoading(false);
            setUpdateRoomNameModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateRoomNameLoading(false);
        }
    }

    async function handleOpenRoom() {
        try {
            const values = await openRoomForm.validateFields();

            setOpenRoomLoading(true)

            const openHotelRoomResponse = await openRoom({
                variables: {
                    input: {
                        propertyRoomId,
                        availableFrom: values.availableFrom,
                    },
                },
            });

            if (openHotelRoomResponse?.data?.openHotelRoom?.error) {
                message.error("Failed to open room");
                setOpenRoomLoading(false);
                return;
            }

            try {
                await taskPromise(openHotelRoomResponse?.data?.openHotelRoom?.updatePropertyCalendarTask?.id);
            }
            catch {
                message.error("Failed to open room");
                setOpenRoomLoading(false);
                return;
            }

            message.success("Room opened");
            setOpenRoomLoading(false);
            setOpenRoomModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setOpenRoomLoading(false);
        }
    }

    async function handleCloseRoom() {
        try {
            const values = await closeRoomForm.validateFields();

            setCloseRoomLoading(true)

            const closeHotelRoomResponse = await closeRoom({
                variables: {
                    input: {
                        propertyRoomId,
                        availableFrom: values.availableFrom,
                    },
                },
            });

            if (closeHotelRoomResponse?.data?.closeHotelRoom?.error) {
                message.error("Failed to close room");
                setCloseRoomLoading(false);
                return;
            }

            try {
                await taskPromise(closeHotelRoomResponse?.data?.closeHotelRoom?.updatePropertyCalendarTask?.id);
            }
            catch {
                message.error("Failed to close room");
                setCloseRoomLoading(false);
                return;
            }

            message.success("Room closed");
            setCloseRoomLoading(false);
            setCloseRoomModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setCloseRoomLoading(false);
        }
    }

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load room"
            />
        );
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {data.propertyRoom.active && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!data.propertyRoom.active && (
                    <StopOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                <Row gutter={[16, 16]}>
                    <Col>
                        <Typography.Text type="secondary">Room</Typography.Text> <Typography.Text>{data.propertyRoom.name}</Typography.Text>
                    </Col>
                    {data.propertyRoom.active && !data.propertyRoom.activeToday && data.propertyRoom.activeFrom && (
                        <Col>
                            <Typography.Text type="secondary">
                                Open from {formatISO(data.propertyRoom.activeFrom, { representation: 'date' })}
                            </Typography.Text>
                        </Col>
                    )}
                    {!data.propertyRoom.active && data.propertyRoom.activeToday && data.propertyRoom.inactiveFrom && (
                        <Col>
                            <Typography.Text type="secondary">
                                Closed from {formatISO(data.propertyRoom.inactiveFrom, { representation: 'date' })}
                            </Typography.Text>
                        </Col>
                    )}
                </Row>
            </Col>
            <Col flex={0}>
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'Edit',
                                key: 'edit',
                            },
                            ...data.propertyRoom.active ? [
                                {
                                    label: 'Close',
                                    key: 'close',
                                },
                            ] : [],
                            ...!data.propertyRoom.active ? [
                                {
                                    label: 'Open',
                                    key: 'open',
                                },
                            ] : [],
                        ],
                        onClick: ({ key }) => handleAction(key)
                    }}
                    trigger={['click']}
                >
                    <Typography.Link>
                        Options
                    </Typography.Link>
                </Dropdown>
            </Col>
            <Modal
                open={updateRoomNameModalOpen}
                title="Update room name"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateRoomNameLoading,
                }}
                onOk={() => handleUpdateRoomName()}
                onCancel={() => setUpdateRoomNameModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={updateRoomNameForm}
                    preserve={false}
                    initialValues={{
                        name: data.propertyRoom.name,
                    }}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                open={openRoomModalOpen}
                title="Open room"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: openRoomLoading,
                }}
                onOk={() => handleOpenRoom()}
                onCancel={() => setOpenRoomModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={openRoomForm}
                    preserve={false}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                >
                    <Form.Item
                        name="availableFrom"
                        label="Available from"
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                open={closeRoomModalOpen}
                title="Close room"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: closeRoomLoading,
                }}
                onOk={() => handleCloseRoom()}
                onCancel={() => setCloseRoomModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={closeRoomForm}
                    preserve={false}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                >
                    <Form.Item
                        name="unavailableFrom"
                        label="Unavailable from"
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}