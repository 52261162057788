import { green, grey, red } from "@ant-design/colors";
import { ApiOutlined, CheckCircleFilled, DeleteOutlined, ExclamationCircleFilled, LoadingOutlined, MinusCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Col, Dropdown, Form, Input, message, Modal, Row, Select, Tooltip, Typography } from "antd";
import { otaNameLabel } from "apartments/common";
import { airbnbFromBackend, airbnbToBackend } from "apartments/common/airbnb";
import { bookingFromBackend, bookingToBackend } from "apartments/common/booking";
import AirbnbSettingsForm from "apartments/forms/AirbnbSettingsForm";
import BookingSettingsForm from "apartments/forms/BookingSettingsForm";
import { taskPromise } from "common/task";
import { useState } from "react";

const QUERY = gql`
    query GetPropertyChannelForHotelChannelOnboarding($propertyChannelId: ID!) {
        propertyChannel(propertyChannelId: $propertyChannelId) {
            id
            otaName
            active
            status
            statusMessage
            mapping
            otaSettings
            property {
                id
                currency
            }
        }
    }
`;

const QUERY_STATUS = gql`
    query GetPropertyChannelStatus($propertyChannelId: ID!) {
        propertyChannel(propertyChannelId: $propertyChannelId) {
            id
            status
            statusMessage
        }
    }
`;

const MUTATION_UPDATE = gql`
    mutation UpdateHotelChannel($input: UpdateHotelChannelInput!) {
        updateHotelChannel(input: $input) {
            error {
                type
                message
            }
            propertyChannel {
                id
                otaName
                active
                mapping
                otaSettings
            }
            syncPropertyChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_CONNECT = gql`
    mutation ConnectPropertyChannel($input: ConnectPropertyChannelInput!) {
        connectPropertyChannel(input: $input) {
            error {
                type
                message
            }
            propertyChannel {
                id
                active
                status
                statusMessage
            }
            syncPropertyChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_DISCONNECT = gql`
    mutation DisconnectPropertyChannel($input: DisconnectPropertyChannelInput!) {
        disconnectPropertyChannel(input: $input) {
            error {
                type
                message
            }
            propertyChannel {
                id
                active
                status
                statusMessage
            }
            syncPropertyChannelUpTask {
                id
            }
        }
    }
`;

const MUTATION_DELETE = gql`
    mutation DeletePropertyChannel($input: DeletePropertyChannelInput!) {
        deletePropertyChannel(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function HotelChannelOnboardingItem(props) {
    const {
        propertyChannelId,
    } = props;

    const [updateMappingModalOpen, setUpdateMappingModalOpen] = useState(false);
    const [updateSettingsModalOpen, setUpdateSettingsModalOpen] = useState(false);
    const [connectModalOpen, setConnectModalOpen] = useState(false);
    const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [disconnectAndDeleteModalOpen, setDisconnectAndDeleteModalOpen] = useState(false);
    const [updateMappingLoading, setUpdateMappingLoading] = useState(false);
    const [updateSettingsLoading, setUpdateSettingsLoading] = useState(false);
    const [connectLoading, setConnectLoading] = useState(false);
    const [disconnectLoading, setDisconnectLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [disconnectAndDeleteLoading, setDisconnectAndDeleteLoading] = useState(false);

    const loading = updateMappingLoading || updateSettingsLoading || connectLoading || disconnectLoading || deleteLoading;

    const { data } = useQuery(QUERY, { variables: { propertyChannelId } });
    const [fetchPropertyChannelStaus] = useLazyQuery(QUERY_STATUS);
    const [updateHotelChannel] = useMutation(MUTATION_UPDATE);
    const [connectPropertyChannel] = useMutation(MUTATION_CONNECT);
    const [disconnectPropertyChannel] = useMutation(MUTATION_DISCONNECT);
    const [deletePropertyChannel] = useMutation(MUTATION_DELETE, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Property',
                    id: data?.propertyChannel?.property?.id,
                }),
                fieldName: 'channels',
            });
        },
    });

    const [mappingForm] = Form.useForm();
    const [settingsForm] = Form.useForm();

    function handleAction(action) {
        switch (action) {
            case 'settings':
                setUpdateSettingsModalOpen(true);
                break;
            case 'disconnect':
                setDisconnectModalOpen(true);
                break;
            case 'connect':
                setConnectModalOpen(true);
                break;
            case 'fix':
                setUpdateMappingModalOpen(true);
                break;
            case 'delete':
                setDeleteModalOpen(true);
                break;
            case 'disconnectAndDelete':
                setDisconnectAndDeleteModalOpen(true);
                break;
            default:
        }
    }

    async function handleUpdateMapping() {
        try {
            const values = await mappingForm.validateFields();

            setUpdateMappingLoading(true)

            const updateHotelChannelResponse = await updateHotelChannel({
                variables: {
                    input: {
                        propertyChannelId,
                        mapping: values.mapping,
                    },
                },
            });

            if (updateHotelChannelResponse?.data?.updateHotelChannel?.error) {
                message.error("Failed to update channel");
                setUpdateMappingLoading(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(updateHotelChannelResponse?.data?.updateHotelChannel?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to update channel");
                setUpdateMappingLoading(false);
                return;
            }

            await fetchPropertyChannelStaus({
                variables: {
                    propertyChannelId,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status === 'success') {
                message.success("Channel updated");
            }
            else {
                message.error("Failed to update channel");
            }

            setUpdateMappingLoading(false);
            setUpdateMappingModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateMappingLoading(false);
        }
    }

    async function handleUpdateSettings() {
        try {
            const values = await settingsForm.validateFields();

            setUpdateSettingsLoading(true)

            let otaSettings;
            if (data?.propertyChannel?.otaName === 'AirBNB') {
                otaSettings = airbnbToBackend(values);
            }
            if (data?.propertyChannel?.otaName === 'BookingCom') {
                otaSettings = bookingToBackend(values);
            }

            const updateHotelChannelResponse = await updateHotelChannel({
                variables: {
                    input: {
                        propertyChannelId,
                        otaSettings,
                    },
                },
            });

            if (updateHotelChannelResponse?.data?.updateHotelChannel?.error) {
                message.error("Failed to update channel");
                setUpdateSettingsLoading(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(updateHotelChannelResponse?.data?.updateHotelChannel?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to update channel");
                setUpdateSettingsLoading(false);
                return;
            }

            await fetchPropertyChannelStaus({
                variables: {
                    propertyChannelId,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status === 'success') {
                message.success("Channel updated");
            }
            else {
                message.error("Failed to update channel");
            }

            setUpdateSettingsLoading(false);
            setUpdateSettingsModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateSettingsLoading(false);
        }
    }

    async function handleConnect() {
        try {
            setConnectLoading(true)

            const connectPropertyChannelResponse = await connectPropertyChannel({
                variables: {
                    input: {
                        propertyChannelId,
                    },
                },
            });

            if (connectPropertyChannelResponse?.data?.connectPropertyChannel?.error) {
                message.error("Failed to connect channel");
                setConnectLoading(false);
                setConnectModalOpen(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(connectPropertyChannelResponse?.data?.connectPropertyChannel?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to connect channel");
                setConnectLoading(false);
                setConnectModalOpen(false);
                return;
            }

            await fetchPropertyChannelStaus({
                variables: {
                    propertyChannelId,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status === 'success') {
                message.success("Channel connected");
            }
            else {
                message.error("Failed to connect channel");
            }

            setConnectLoading(false);
            setConnectModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setConnectLoading(false);
        }
    }

    async function handleDisconnect() {
        try {
            setDisconnectLoading(true)

            const disconnectPropertyChannelResponse = await disconnectPropertyChannel({
                variables: {
                    input: {
                        propertyChannelId,
                    },
                },
            });

            if (disconnectPropertyChannelResponse?.data?.disconnectPropertyChannel?.error) {
                message.error("Failed to disconnect channel");
                setDisconnectLoading(false);
                setDisconnectModalOpen(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(disconnectPropertyChannelResponse?.data?.disconnectPropertyChannel?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to disconnect channel");
                setDisconnectLoading(false);
                setDisconnectModalOpen(false);
                return;
            }

            await fetchPropertyChannelStaus({
                variables: {
                    propertyChannelId,
                },
                fetchPolicy: 'network-only',
            });

            if (taskResult?.status === 'success') {
                message.success("Channel disconnected");
            }
            else {
                message.error("Failed to disconnect channel");
            }

            setDisconnectLoading(false);
            setDisconnectModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setDisconnectLoading(false);
        }
    }

    async function handleDelete() {
        try {
            setDeleteLoading(true)

            const deletePropertyChannelResponse = await deletePropertyChannel({
                variables: {
                    input: {
                        propertyChannelId,
                    },
                },
            });

            if (deletePropertyChannelResponse?.data?.deletePropertyChannel?.error) {
                message.error("Failed to delete channel");
                setDisconnectLoading(false);
                setDeleteModalOpen(false);
                return;
            }

            message.success("Channel deleted");
            setDeleteLoading(false);
            setDeleteModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setDeleteLoading(false);
        }
    }

    async function handleDisconnectAndDelete() {
        try {
            setDisconnectAndDeleteLoading(true)

            const disconnectPropertyChannelResponse = await disconnectPropertyChannel({
                variables: {
                    input: {
                        propertyChannelId,
                    },
                },
            });

            if (disconnectPropertyChannelResponse?.data?.disconnectPropertyChannel?.error) {
                message.error("Failed to delete channel");
                setDisconnectAndDeleteLoading(false);
                setDisconnectAndDeleteModalOpen(false);
                return;
            }

            let taskResult;
            try {
                taskResult = await taskPromise(disconnectPropertyChannelResponse?.data?.disconnectPropertyChannel?.syncPropertyChannelUpTask?.id);
            }
            catch {
                message.error("Failed to delete channel");
                setDisconnectAndDeleteLoading(false);
                setDisconnectAndDeleteModalOpen(false);
                return;
            }

            if (taskResult?.status !== 'success') {
                message.error("Failed to delete channel");
                setDisconnectAndDeleteLoading(false);
                setDisconnectAndDeleteModalOpen(false);
                return;
            }

            const deletePropertyChannelResponse = await deletePropertyChannel({
                variables: {
                    input: {
                        propertyChannelId,
                    },
                },
            });

            if (deletePropertyChannelResponse?.data?.deletePropertyChannel?.error) {
                message.error("Failed to delete channel");
                setDisconnectAndDeleteLoading(false);
                setDisconnectAndDeleteModalOpen(false);
                return;
            }

            message.success("Channel deleted");
            setDisconnectAndDeleteLoading(false);
            setDisconnectAndDeleteModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setDisconnectAndDeleteLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {(loading || data?.propertyChannel?.status == null) && (
                    <LoadingOutlined />
                )}
                {!loading && data?.propertyChannel?.status === 'success' && data?.propertyChannel.active && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!loading && data?.propertyChannel?.status === 'success' && !data?.propertyChannel.active && (
                    <MinusCircleOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
                {!loading && data?.propertyChannel?.status !== 'success' && data?.propertyChannel?.status != null && (
                    <ExclamationCircleFilled
                        style={{
                            color: red.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                {data?.propertyChannel?.status === 'success' && data?.propertyChannel.active && (
                    <Typography.Text>
                        {otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)} connected
                    </Typography.Text>
                )}
                {data?.propertyChannel?.status === 'success' && !data?.propertyChannel.active && (
                    <Typography.Text>
                        {otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)} disconnected
                    </Typography.Text>
                )}
                {data?.propertyChannel?.status !== 'success' && data?.propertyChannel?.status != null && (
                    <Tooltip title={data?.propertyChannel?.statusMessage}>
                        {otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)} error
                    </Tooltip>
                )}
                {data?.propertyChannel?.status == null && (
                    <Typography.Text>
                        {otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)}
                    </Typography.Text>
                )}
            </Col>
            <Col>
                {data?.propertyChannel?.status === 'success' && (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: 'Settings',
                                    key: 'settings',
                                },
                                ...data?.propertyChannel?.active ? [
                                    {
                                        label: 'Disconnect',
                                        key: 'disconnect',
                                        danger: true,
                                    },
                                ] : [],
                                ...!data?.propertyChannel?.active ? [
                                    {
                                        label: 'Connect',
                                        key: 'connect',
                                    },
                                    {
                                        label: 'Delete',
                                        key: 'delete',
                                        danger: true,
                                    },
                                ] : [],
                            ],
                            onClick: ({ key }) => handleAction(key),
                        }}
                        trigger={["click"]}
                    >
                        <Typography.Link>
                            Options
                        </Typography.Link>
                    </Dropdown>
                )}
                {data?.propertyChannel?.status !== 'success' && (
                    <Dropdown
                        menu={{
                            items: [
                                ...['already_exists', 'airbnb_listing_not_found', 'booking_connection_test_failed', 'unknown_error'].includes(data?.propertyChannel?.status) ? [
                                    {
                                        label: 'Fix',
                                        key: 'fix',
                                    },
                                ] : [],
                                ...['currency_mismatch', 'airbnb_no_account', 'airbnb_listing_unlisted', 'airbnb_multiple_room_types', 'booking_connection_test_failed', 'unknown_error'].includes(data?.propertyChannel?.status) ? [
                                    {
                                        label: 'Reconnect',
                                        key: 'connect',
                                    },
                                ] : [],
                                ...data?.propertyChannel?.data?.status === 'success' && data?.propertyChannel?.active ? [
                                    {
                                        label: 'Disconnect',
                                        key: 'disconnect',
                                        danger: true,
                                    },
                                ] : [],
                                ...data?.propertyChannel?.data?.status === 'success' && !data?.propertyChannel?.active ? [
                                    {
                                        label: 'Connect',
                                        key: 'connect',
                                    },
                                    {
                                        label: 'Delete',
                                        key: 'delete',
                                        danger: true,
                                    },
                                ] : [],
                                ...data?.propertyChannel?.data?.status !== 'success' && data?.propertyChannel?.active ? [
                                    {
                                        label: 'Delete',
                                        key: 'disconnectAndDelete',
                                        danger: true,
                                    },
                                ] : [],
                            ],
                            onClick: ({ key }) => handleAction(key),
                        }}
                        trigger={["click"]}
                    >
                        <Typography.Link>
                            Options
                        </Typography.Link>
                    </Dropdown>
                )}
            </Col>
            <Modal
                open={updateSettingsModalOpen}
                title={`Channel settings - ${otaNameLabel(data?.propertyChannel?.otaName, data?.propertyChannel?.mapping)}`}
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateSettingsLoading,
                }}
                onOk={() => handleUpdateSettings()}
                onCancel={() => setUpdateSettingsModalOpen(false)}
                destroyOnClose
                width={700}
            >
                {data?.propertyChannel?.otaName === 'AirBNB' && (
                    <AirbnbSettingsForm
                        form={settingsForm}
                        initialValues={airbnbFromBackend(data?.propertyChannel?.otaSettings)}
                        currency={data?.propertyChannel?.property?.currency}
                        maxGuestCount={1}
                        preserve={false}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 18,
                        }}
                    />
                )}
                {data?.propertyChannel?.otaName === 'BookingCom' && (
                    <BookingSettingsForm
                        form={settingsForm}
                        initialValues={bookingFromBackend(data?.propertyChannel?.otaSettings)}
                        currency={data?.propertyChannel?.property?.currency}
                        maxGuestCount={10}
                        preserve={false}
                        labelCol={{
                            span: 6,
                        }}
                        wrapperCol={{
                            span: 18,
                        }}
                    />
                )}
            </Modal>
            <Modal
                open={updateMappingModalOpen}
                title="Update channel"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateMappingLoading,
                }}
                onOk={() => handleUpdateMapping()}
                onCancel={() => setUpdateMappingModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Row gutter={[16, 16]}>
                    {data?.propertyChannel?.statusMessage && (
                        <Col span={24}>
                            <Alert
                                type="error"
                                showIcon
                                message={data?.propertyChannel?.statusMessage}
                            />
                        </Col>
                    )}
                    <Col span={24}>
                        <Form
                            form={mappingForm}
                            preserve={false}
                            initialValues={{
                                otaName: data?.propertyChannel.otaName,
                                mapping: data?.propertyChannel.mapping,
                            }}
                            labelCol={{
                                span: 6,
                            }}
                            wrapperCol={{
                                span: 18,
                            }}
                        >
                            <Form.Item
                                name="otaName"
                                label="Channel"
                                rules={[{ required: true, message: 'Channel is required' }]}
                            >
                                <Select
                                    options={[
                                        {
                                            label: 'Booking.com',
                                            value: 'BookingCom',
                                        },
                                        {
                                            label: 'Airbnb.com',
                                            value: 'AirBNB',
                                        },
                                    ]}
                                    disabled
                                    style={{
                                        width: '200px',
                                    }}
                                />
                            </Form.Item>
                            {data?.propertyChannel.otaName === 'BookingCom' && (
                                <>
                                    <Form.Item
                                        name={['mapping', 'hotel_id']}
                                        label="Hotel ID"
                                        rules={[{ required: true, message: 'Hotel ID is required' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            )}
                            {data?.propertyChannel.otaName === 'AirBNB' && (
                                <Form.Item
                                    name={['mapping', 'listing_id']}
                                    label="Listing ID"
                                    rules={[{ required: true, message: 'Listing ID is required' }]}
                                >
                                    <Input />
                                </Form.Item>
                            )}
                        </Form>
                    </Col>
                </Row>
            </Modal>
            <Modal
                open={connectModalOpen}
                title="Connect channel"
                okText="Connect"
                okButtonProps={{
                    icon: <ApiOutlined />,
                    loading: connectLoading,
                }}
                onOk={() => handleConnect()}
                onCancel={() => setConnectModalOpen(false)}
                destroyOnClose
                width={700}
            >
                Are you sure you want to connect this channel?
            </Modal>
            <Modal
                open={disconnectModalOpen}
                title="Disconnect channel"
                okText="Disconnect"
                okButtonProps={{
                    icon: <DeleteOutlined />,
                    loading: disconnectLoading,
                    danger: true,
                }}
                onOk={() => handleDisconnect()}
                onCancel={() => setDisconnectModalOpen(false)}
                destroyOnClose
                width={700}
            >
                Are you sure you want to disconnect this channel?
            </Modal>
            <Modal
                open={deleteModalOpen}
                title="Delete channel"
                okText="Delete"
                okButtonProps={{
                    icon: <DeleteOutlined />,
                    loading: deleteLoading,
                    danger: true,
                }}
                onOk={() => handleDelete()}
                onCancel={() => setDeleteModalOpen(false)}
                destroyOnClose
                width={700}
            >
                Are you sure you want to delete this channel?
            </Modal>
            <Modal
                open={disconnectAndDeleteModalOpen}
                title="Delete channel"
                okText="Delete"
                okButtonProps={{
                    icon: <DeleteOutlined />,
                    loading: disconnectAndDeleteLoading,
                    danger: true,
                }}
                onOk={() => handleDisconnectAndDelete()}
                onCancel={() => setDisconnectAndDeleteModalOpen(false)}
                destroyOnClose
                width={700}
            >
                Are you sure you want to delete this channel?
            </Modal>
        </Row >
    );
}