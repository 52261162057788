import { gql, useQuery } from "@apollo/client";
import { Alert, Card, Col, PageHeader, Row } from "antd";
import ApartmentOnboarding from "apartments/components/apartment-onboarding/ApartmentOnboarding";
import HotelOnboarding from "apartments/components/hotel-onboarding/HotelOnboarding";
import { useNavigate, useParams } from "react-router-dom";

const QUERY = gql`
    query GetPropertyForOnboarding($propertyId: ID!) {
        property(propertyId: $propertyId) {
            id
            name
            type
        }
    }
`;

export default function OnboardPropertyView() {
    const { propertyId } = useParams();
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(QUERY, { variables: { propertyId } });

    return (
        <PageHeader
            title={data?.property?.name}
            onBack={() => navigate(-1)}
        >
            {loading && (
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card loading />
                    </Col>
                </Row>
            )}
            {error && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load property"
                />
            )}
            {!loading && data?.property?.type === 'apartment' && (
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Card>
                            <ApartmentOnboarding
                                propertyId={data?.property?.id}
                            />
                        </Card>
                    </Col>
                </Row>
            )}
            {!loading && data?.property?.type === 'hotel' && (
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <HotelOnboarding
                            propertyId={data?.property?.id}
                        />
                    </Col>
                </Row>
            )}
        </PageHeader>
    );
}