import { gql, useMutation } from "@apollo/client";
import { Alert, Form, Select } from "antd";
import { useWaitForTask } from "common/task";
import { useEffect } from "react";

const MUTATION = gql`
    mutation CreateGetPropertyChannelRoomsAndRatesTask($input: CreateGetPropertyChannelRoomsAndRatesTaskInput!) {
        createGetPropertyChannelRoomsAndRatesTask(input: $input) {
            error {
                type
                message
            }
            getPropertyChannelRoomsAndRatesTask {
                id
            }
        }
    }
`;

export default function BookingMappingForm(props) {
    const {
        form,
        propertyChannelId,
        ...otherProps
    } = props;

    const [createGetPropertyChannelRoomsAndRatesTask, { data: createTaskData }] = useMutation(MUTATION);
    const { result, loading, error } = useWaitForTask(createTaskData?.createGetPropertyChannelRoomsAndRatesTask?.getPropertyChannelRoomsAndRatesTask?.id);

    const roomId = Form.useWatch(['mapping', 'room_id'], form);

    useEffect(() => {
        if (propertyChannelId) {
            createGetPropertyChannelRoomsAndRatesTask({
                variables: {
                    input: {
                        propertyChannelId,
                    },
                },
            });
        }
    }, [createGetPropertyChannelRoomsAndRatesTask, propertyChannelId]);

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to get rooms and rates. Please retry"
            />
        );
    }

    return (
        <Form
            form={form}
            preserve={false}
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 18,
            }}
            {...otherProps}
        >
            <Form.Item
                name={['mapping', 'room_id']}
                label="Room ID"
                rules={[{ required: true, message: 'Room ID is required' }]}
            >
                <Select
                    options={
                        [...result?.rooms ?? []]
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(room => ({
                                label: `${room.name} (${room.room_id})`,
                                value: room.room_id,
                            }))
                    }
                    loading={loading}
                />
            </Form.Item>
            {roomId && (
                <Form.Item
                    name={['mapping', 'rate_id']}
                    label="Rate ID"
                    rules={[{ required: true, message: 'Rate ID is required' }]}
                >
                    <Select
                        options={
                            [...[...result?.rooms ?? []].find(room => room.room_id === roomId)?.rates ?? []]
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(rate => ({
                                    label: `${rate.name} (${rate.rate_id})`,
                                    value: rate.rate_id,
                                }))
                        }
                        loading={loading}
                    />
                </Form.Item>
            )}
        </Form>
    );
}