import { gql, useLazyQuery } from "@apollo/client";
import { AutoComplete, Input } from "antd";
import { useState } from "react";

const QUERY = gql`
    query GetGeocodindResultsForLocationInput($query: String!) {
        geocoding(query: $query) {
            id
            label
            latitude
            longitude
            timezone
        }
    }
`;

export default function LocationInput(props) {
    const {
        value,
        onChange,
    } = props;

    const [options, setOptions] = useState(
        value ? [
            {
                value: value.address,
                location: {
                    address: value.address,
                    latitude: value.latitude,
                    longitude: value.longitude,
                    timezone: value.timezone,
                },
            }
        ] : []
    );
    const [getGeocoding, { loading, error }] = useLazyQuery(QUERY);

    function handleOnSearch(query) {
        getGeocoding({ variables: { query } })
            .then(response => {
                setOptions(
                    [...response?.data?.geocoding ?? []]
                        .map(item => ({
                            value: item.label,
                            location: {
                                address: item.label,
                                latitude: item.latitude,
                                longitude: item.longitude,
                                timezone: item.timezone,
                            },
                        }))
                );
            });
    }

    return (
        <AutoComplete
            options={options}
            defaultValue={value?.address}
            onSelect={(_, option) => onChange(option.location)}
            style={{
                width: '100%',
            }}
        >
            <Input.Search
                onSearch={value => handleOnSearch(value)}
                loading={loading}
                status={error && "error"}
                placeholder="Address"
                enterButton
            />
        </AutoComplete>
    )
}