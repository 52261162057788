import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Form, Row, Switch, Tabs } from "antd";
import BookingAgeRestrictionInput from "apartments/components/booking-age-restriction-input/BookingAgeRestrictionInput";
import BookingAllowChildrenInput from "apartments/components/booking-allow-children-input/BookingAllowChildrenInput";
import BookingAllowPetsInput from "apartments/components/booking-allow-pets-input/BookingAllowPetsInput";
import BookingCancelationPolicyInput from "apartments/components/booking-cancelation-policy-input/BookingCancelationPolicyInput";
import BookingCheckInOutTimeInput from "apartments/components/booking-check-in-out-time-input/BookingCheckInOutTimeInput";
import BookingCurfewInput from "apartments/components/booking-curfew-input/BookingCurfewInput";
import BookingDiscountInput from "apartments/components/booking-discount-input/BookingDiscountInput";
import BookingFeeInput from "apartments/components/booking-fee-input/BookingFeeInput";
import BookingNonRefundableRateInput from "apartments/components/booking-non-refundable-rate-input/BookingNonRefundableInput";
import BookingQuietHoursInput from "apartments/components/booking-quiet-hours-input/BookingQuietHoursInput";
import BookingRenovationInput from "apartments/components/booking-renovation-input/BookingRenovationInput";
import GuestPricingInput from "apartments/components/guest-pricing-input/GuestPricingInput";
import DeletableInputWrapper from "components/deletable-input-wrapper/DeletableInputWrapper";

export default function BookingSettingsForm(props) {
    const {
        form,
        currency,
        maxGuestCount,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            {...otherProps}
        >
            <Tabs
                items={[
                    {
                        label: 'Guest pricing',
                        key: 'guestPricing',
                        children: (
                            <>
                                <Form.Item
                                    name="guestPricing"
                                    label="Guest pricing"
                                    rules={[{
                                        validator(_, guestPricing) {
                                            if (guestPricing == null) {
                                                return Promise.reject(new Error('Guest pricing is required'));
                                            }
                                            if (guestPricing.mode == null) {
                                                return Promise.reject(new Error('Pricing mode is required'));
                                            }
                                            if (guestPricing.mode === 'simple') {
                                                if (guestPricing?.pricing?.baseGuestCount == null) {
                                                    return Promise.reject(new Error('Base guest price is required'));
                                                }
                                                if (guestPricing?.pricing?.extraGuestFee == null) {
                                                    return Promise.reject(new Error('Extra guest fee is required'));
                                                }
                                            }
                                            if (guestPricing.mode === 'complex') {
                                                if (guestPricing?.pricing == null || guestPricing?.pricing?.some(item => item == null)) {
                                                    return Promise.reject(new Error('You need to specify all prices'));
                                                }
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <GuestPricingInput
                                        currency={currency}
                                        maxGuestCount={maxGuestCount}
                                    />
                                </Form.Item>
                            </>
                        ),
                    },
                    {
                        label: 'Fees',
                        key: 'fees',
                        children: (
                            <Form.List
                                name="fees"
                                rules={[{
                                    validator(_, fees) {
                                        if (fees.some((fee, index) => fees.findIndex(otherFee => otherFee?.type === fee?.type) !== index)) {
                                            return Promise.reject(new Error('Duplicated fees are not allowed'));
                                        }

                                        return Promise.resolve();
                                    },
                                }]}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <Row justify="end">
                                                    <Col>
                                                        <Button
                                                            icon={<PlusOutlined />}
                                                            onClick={() => add()}
                                                        >
                                                            Add fee
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                {fields.length === 0 && (
                                                    <Empty
                                                        description="There are no fees"
                                                    />
                                                )}
                                                {fields.map(field => (
                                                    <Form.Item
                                                        {...field}
                                                        wrapperCol={{
                                                            span: 24,
                                                        }}
                                                        rules={[{
                                                            validator(_, fee) {
                                                                if (fee == null) {
                                                                    return Promise.reject(new Error('Fee is required'));
                                                                }
                                                                if (fee.type == null) {
                                                                    return Promise.reject(new Error('Type is required'));
                                                                }
                                                                if (fee.amount == null) {
                                                                    return Promise.reject(new Error('Amount is required'));
                                                                }
                                                                if (fee.unit == null) {
                                                                    return Promise.reject(new Error('Unit is required'));
                                                                }

                                                                return Promise.resolve();
                                                            },
                                                        }]}
                                                    >
                                                        <DeletableInputWrapper
                                                            onDelete={() => remove(field.name)}
                                                        >
                                                            <BookingFeeInput
                                                                currency={currency}
                                                            />
                                                        </DeletableInputWrapper>
                                                    </Form.Item>
                                                ))}
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Form.List>
                        ),
                    },
                    {
                        label: 'Discounts',
                        key: 'discounts',
                        children: (
                            <Form.List name="discounts">
                                {(fields, { add, remove }) => (
                                    <>
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>
                                                <Row justify="end">
                                                    <Col>
                                                        <Button
                                                            icon={<PlusOutlined />}
                                                            onClick={() => add()}
                                                        >
                                                            Add discount
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                {fields.length === 0 && (
                                                    <Empty
                                                        description="There are no discounts"
                                                    />
                                                )}
                                                {fields.map(field => (
                                                    <Form.Item
                                                        {...field}
                                                        wrapperCol={{
                                                            span: 24,
                                                        }}
                                                        rules={[{
                                                            validator(_, discount) {
                                                                if (discount == null) {
                                                                    return Promise.reject(new Error('Discount is required'));
                                                                }
                                                                if (discount.amount == null) {
                                                                    return Promise.reject(new Error('Amount is required'));
                                                                }
                                                                if (discount.bookingPeriod == null) {
                                                                    return Promise.reject(new Error('Booking period is required'));
                                                                }

                                                                return Promise.resolve();
                                                            }
                                                        }]}
                                                    >
                                                        <DeletableInputWrapper
                                                            onDelete={() => remove(field.name)}
                                                        >
                                                            <BookingDiscountInput />
                                                        </DeletableInputWrapper>
                                                    </Form.Item>
                                                ))}
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Form.List>
                        ),
                    },
                    {
                        label: 'Booking',
                        key: 'booking',
                        children: (
                            <>
                                <Form.Item
                                    name="nonRefundableRate"
                                    label="Non-refundable rate"
                                    rules={[{
                                        validator(_, nonRefundableRate) {
                                            if (nonRefundableRate == null) {
                                                return Promise.reject(new Error('Non-refundable rate is required'));
                                            }
                                            if (nonRefundableRate.enabled) {
                                                if (nonRefundableRate?.discount == null) {
                                                    return Promise.reject(new Error('Discount is required'));
                                                }
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <BookingNonRefundableRateInput />
                                </Form.Item>
                                <Form.Item
                                    name="cancelationPolicy"
                                    label="Cancelation policy"
                                    rules={[{
                                        validator(_, cancelationPolicy) {
                                            if (cancelationPolicy == null) {
                                                return Promise.reject(new Error('Cancelation policy is required'));
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <BookingCancelationPolicyInput />
                                </Form.Item>
                            </>
                        ),
                    },
                    {
                        label: 'Rules',
                        key: 'rules',
                        children: (
                            <>
                                <Form.Item
                                    name="checkInTimeStart"
                                    label="Check-in start"
                                    rules={[{ required: true }]}
                                >
                                    <BookingCheckInOutTimeInput />
                                </Form.Item>
                                <Form.Item
                                    name="checkInTimeEnd"
                                    label="Check-in end"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, checkInTimeEnd) {
                                                const start = getFieldValue('checkInTimeStart');

                                                if (start != null) {
                                                    const end = checkInTimeEnd;
                                                    if (start > end) {
                                                        return Promise.reject(new Error('Check-in start has to be before check-in end'));
                                                    }
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <BookingCheckInOutTimeInput />
                                </Form.Item>
                                <Form.Item
                                    name="checkOutTimeStart"
                                    label="Check-out start"
                                >
                                    <BookingCheckInOutTimeInput />
                                </Form.Item>
                                <Form.Item
                                    name="checkOutTimeEnd"
                                    label="Check-out end"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, checkOutTimeEnd) {
                                                const start = getFieldValue('checkOutTimeStart');
                                                const end = checkOutTimeEnd;

                                                if (start != null && end == null) {
                                                    return Promise.reject(new Error('Check-out end is required'));
                                                }

                                                if (start != null && end != null) {
                                                    if (start > end) {
                                                        return Promise.reject(new Error('Check-out start has to be before check-out end'));
                                                    }
                                                }

                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <BookingCheckInOutTimeInput />
                                </Form.Item>
                                <Form.Item
                                    name="curfew"
                                    label="Curfew"
                                    rules={[{
                                        validator(_, curfew) {
                                            if (curfew?.enabled) {
                                                if (curfew?.start == null || curfew?.end == null) {
                                                    return Promise.reject(new Error('Start and end time is required'));
                                                }
                                            }

                                            return Promise.resolve();
                                        },
                                    }]}
                                >
                                    <BookingCurfewInput />
                                </Form.Item>
                                <Form.Item
                                    name="quietHours"
                                    label="Quiet hours"
                                    rules={[{
                                        validator(_, quietHours) {
                                            if (quietHours?.enabled) {
                                                if (quietHours?.start == null || quietHours?.end == null) {
                                                    return Promise.reject(new Error('Start and end time is required'));
                                                }
                                            }

                                            return Promise.resolve();
                                        },
                                    }]}
                                >
                                    <BookingQuietHoursInput />
                                </Form.Item>
                                <Form.Item
                                    name="renovation"
                                    label="Renovation"
                                    rules={[{
                                        validator(_, renovation) {
                                            if (renovation?.enabled) {
                                                if (renovation?.start == null || renovation?.end == null) {
                                                    return Promise.reject(new Error('Start and end is required'));
                                                }
                                            }

                                            return Promise.resolve();
                                        },
                                    }]}
                                >
                                    <BookingRenovationInput />
                                </Form.Item>
                                <Form.Item
                                    name="ageRestriction"
                                    label="Age restriction"
                                    rules={[{
                                        validator(_, ageRestriction) {
                                            if (ageRestriction?.enabled) {
                                                if (ageRestriction?.min == null || ageRestriction?.max == null) {
                                                    return Promise.reject(new Error('Min age and max age is required'));
                                                }
                                            }

                                            return Promise.resolve();
                                        }
                                    }]}
                                >
                                    <BookingAgeRestrictionInput />
                                </Form.Item>
                                <Form.Item
                                    name="allowChildren"
                                    label="Allow children"
                                >
                                    <BookingAllowChildrenInput />
                                </Form.Item>
                                <Form.Item
                                    name="allowPets"
                                    label="Allow pets"
                                >
                                    <BookingAllowPetsInput />
                                </Form.Item>
                                <Form.Item
                                    name="allowSmoking"
                                    label="Allow smoking"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            </>
                        ),
                    }
                ]}
            />
        </Form>
    );
}