import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Alert, Card, Col, Form, Input, message, Modal, Row, Skeleton, Typography } from "antd";
import HotelRoomTypeOccupancyOnboardingItem from "./HotelRoomTypeOccupancyOnboardingItem";
import HotelRoomTypeAvailabilityOnboardingItem from "./HotelRoomTypeAvailabilityOnboardingItem";
import HotelRoomTypePricingOnboardingItem from "./HotelRoomTypePricingOnboardingItem";
import { useState } from "react";
import { taskPromise } from "common/task";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import HotelRoomOnboarding from "./HotelRoomOnboarding";
import HotelRoomTypeChannelOnboardingItem from "./HotelRoomTypeChannelOnboardingItem";

const QUERY = gql`
    query GetPropertyRoomTypeForHotelRoomTypeOnboarding($propertyRoomTypeId: ID!) {
        propertyRoomType(propertyRoomTypeId: $propertyRoomTypeId) {
            id
            name
            maxGuestCount
            weekdayMinStay
            defaultPrice
            rooms {
                id
                name
                active
                activeToday
                activeFrom
                inactiveFrom
            }
            property {
                id
                currency
                channels {
                    id
                    active
                    status
                }
            }
            channelMappings {
                id
                channel {
                    id
                }
                active
                mapping
                status
                statusMessage
            }
        }
    }
`;

const QUERY_CHANNEL = gql`
    query GetPropertyChannelAfterHotelRoomTypeOnboarding($propertyChannelId: ID!) {
        propertyChannel(propertyChannelId: $propertyChannelId) {
            id
            status
            statusMessage
            roomTypeMappings {
                id
                status
                statusMessage
            }
        }
    }
`;

const MUTATION_CREATE = gql`
    mutation CreateHotelRoom($input: CreateHotelRoomInput!) {
        createHotelRoom(input: $input) {
            error {
                type
                message
            }
            syncPropertyUpTask {
                id
            }
        }
    }
`;

const MUTATION_UPDATE = gql`
    mutation UpdateHotelRoomTypeName($input: UpdateHotelRoomTypeNameInput!) {
        updateHotelRoomTypeName(input: $input) {
            error {
                type
                message
            }
            propertyRoomType {
                id
                name
            }
            syncPropertyUpTask {
                id
            }
            syncPropertyChannelUpTasks {
                id
            }
        }
    }
`;

export default function HotelRoomTypeOnboarding(props) {
    const {
        propertyRoomTypeId,
    } = props;

    const [createRoomModalOpen, setCreateRoomModalOpen] = useState(false);
    const [updateRoomTypeModalOpen, setUpdateRoomTypeModalOpen] = useState(false);
    const [createRoomLoading, setCreateRoomLoading] = useState(false);
    const [updateRoomTypeLoading, setUpdateRoomTypeLoading] = useState(false);

    const { data, error } = useQuery(QUERY, { variables: { propertyRoomTypeId } });
    const [fetchPropertyChannel] = useLazyQuery(QUERY_CHANNEL);
    const [createHotelRoom] = useMutation(MUTATION_CREATE, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'PropertyRoomType',
                    id: propertyRoomTypeId,
                }),
                fieldName: 'rooms',
            });
        },
    });
    const [updateHotelRoomType] = useMutation(MUTATION_UPDATE);

    const [formCreateRoom] = Form.useForm();
    const [formUpdateRoomType] = Form.useForm();

    async function handleCreateRoom() {
        try {
            const values = await formCreateRoom.validateFields();

            setCreateRoomLoading(true)

            const createHotelRoomResponse = await createHotelRoom({
                variables: {
                    input: {
                        propertyRoomTypeId,
                        name: values.name,
                    },
                },
            });

            if (createHotelRoomResponse?.data?.createHotelRoom?.error) {
                message.error("Failed to create room");
                setCreateRoomLoading(false);
                return;
            }

            try {
                await Promise.all([
                    taskPromise(createHotelRoomResponse?.data?.createHotelRoom?.syncPropertyUpTask?.id),
                    ...[...createHotelRoomResponse?.data?.createHotelRoom?.syncPropertyChannelUpTasks ?? []]
                        .map(task => taskPromise(task.id)),
                ]);
            }
            catch {
                message.error("Failed to create room");
                setCreateRoomLoading(false);
                return;
            }

            await Promise.all(
                [...data?.propertyRoomType?.property?.channels ?? []]
                    .map(channel => (
                        fetchPropertyChannel({
                            variables: {
                                propertyChannelId: channel.id,
                            },
                            fetchPolicy: 'network-only',
                        })
                    ))
            );

            message.success("Room created");
            setCreateRoomLoading(false);
            setCreateRoomModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setCreateRoomLoading(false);
        }
    }

    async function handleUpdateRoomType() {
        try {
            const values = await formUpdateRoomType.validateFields();

            setUpdateRoomTypeLoading(true)

            const updateHotelRoomTypeResponse = await updateHotelRoomType({
                variables: {
                    input: {
                        propertyRoomTypeId,
                        name: values.name,
                    },
                },
            });

            if (updateHotelRoomTypeResponse?.data?.updateHotelRoomTypeName?.error) {
                message.error("Failed to update name");
                setUpdateRoomTypeLoading(false);
                return;
            }

            try {
                await taskPromise(updateHotelRoomTypeResponse?.data?.updateHotelRoomTypeName?.syncPropertyUpTask?.id);
            }
            catch {
                message.error("Failed to update name");
                setUpdateRoomTypeLoading(false);
                return;
            }

            message.success("Name updated");
            setUpdateRoomTypeLoading(false);
            setUpdateRoomTypeModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateRoomTypeLoading(false);
        }
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load room type"
            />
        );
    }

    if (!data) {
        return (
            <Skeleton />
        );
    }

    return (
        <Card
            title={data.propertyRoomType.name}
            extra={
                <EditOutlined
                    onClick={() => setUpdateRoomTypeModalOpen(true)}
                />
            }
            actions={[
                <Typography.Link
                    onClick={() => setCreateRoomModalOpen(true)}
                >
                    Add room
                </Typography.Link>
            ]}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <HotelRoomTypeOccupancyOnboardingItem
                        propertyRoomTypeId={propertyRoomTypeId}
                    />
                </Col>
                <Col span={24}>
                    <HotelRoomTypeAvailabilityOnboardingItem
                        propertyRoomTypeId={propertyRoomTypeId}
                    />
                </Col>
                <Col span={24}>
                    <HotelRoomTypePricingOnboardingItem
                        propertyRoomTypeId={propertyRoomTypeId}
                    />
                </Col>
                {[...data?.propertyRoomType?.property?.channels ?? []]
                    .filter(channel => channel.active && channel.status === 'success')
                    .sort((a, b) => a.id.localeCompare(b.id))
                    .map(channel => (
                        <Col
                            span={24}
                            key={channel.id}
                        >
                            <HotelRoomTypeChannelOnboardingItem
                                propertyChannelId={channel.id}
                                propertyRoomTypeId={propertyRoomTypeId}
                            />
                        </Col>
                    ))}
                {[...data?.propertyRoomType?.rooms ?? []].length === 0 && (
                    <Col span={24}>
                        <Row justify="center">
                            <Col>
                                <Typography.Text type="secondary">
                                    No rooms
                                </Typography.Text>
                            </Col>
                        </Row>
                    </Col>
                )}
                {[...data?.propertyRoomType?.rooms ?? []]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(room => (
                        <Col
                            span={24}
                            key={room.id}
                        >
                            <HotelRoomOnboarding
                                propertyRoomId={room.id}
                            />
                        </Col>
                    ))}
            </Row>
            <Modal
                open={updateRoomTypeModalOpen}
                title="Update room type"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateRoomTypeLoading,
                }}
                onOk={() => handleUpdateRoomType()}
                onCancel={() => setUpdateRoomTypeModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={formUpdateRoomType}
                    initialValues={{
                        name: data?.propertyRoomType?.name,
                    }}
                    preserve={false}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                open={createRoomModalOpen}
                title="Add room"
                okText="Add"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: createRoomLoading,
                }}
                onOk={() => handleCreateRoom()}
                onCancel={() => setCreateRoomModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={formCreateRoom}
                    preserve={false}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Name is required' }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </Card>
    );
}