import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined, SaveOutlined } from "@ant-design/icons";
import { ApolloError, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Col, Form, message, Modal, Row, Typography } from "antd";
import { taskPromise } from "common/task";
import CurrencySelect from "components/CurrencySelect";
import { useState } from "react";

const QUERY = gql`
    query GetPropertyPricingSettingsForHotelPricingSettingsOnboarding($propertyId: ID!) {
        property(propertyId: $propertyId) {
            id
            currency
            channels {
                id
            }
        }
    }
`;

const QUERY_CHANNEL = gql`
    query GetPropertyChannelAfterHotelPricingSettingsOnboarding($propertyChannelId: ID!) {
        propertyChannel(propertyChannelId: $propertyChannelId) {
            id
            status
            statusMessage
            roomTypeMappings {
                id
                status
                statusMessage
            }
        }
    }
`;

const MUTATION = gql`
    mutation UpdateHotelPricingSettings($input: UpdateHotelPricingSettingsInput!) {
        updateHotelPricingSettings(input: $input) {
            error {
                type
                message
            }
            property {
                id
                currency
            }
            syncPropertyUpTask {
                id
            }
            syncPropertyChannelUpTasks {
                id
            }
        }
    }
`;

export default function HotelPricingOnboardingItem(props) {
    const {
        propertyId,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data } = useQuery(QUERY, { variables: { propertyId } });
    const [fetchPropertyChannel] = useLazyQuery(QUERY_CHANNEL);
    const [updateHotelPricingSettings] = useMutation(MUTATION);

    const [form] = Form.useForm();

    const onboardingDone = data?.property?.currency != null;

    async function handleSave() {
        try {
            const values = await form.validateFields();

            setLoading(true)

            const updateHotelPricingSettingsResponse = await updateHotelPricingSettings({
                variables: {
                    input: {
                        propertyId,
                        currency: values.currency,
                    },
                },
            });

            if (updateHotelPricingSettingsResponse?.data?.updateHotelPricingSettings?.error) {
                message.error("Failed to update property pricing");
                setLoading(false);
                return;
            }

            try {
                await Promise.all([
                    taskPromise(updateHotelPricingSettingsResponse?.data?.updateHotelPricingSettings?.syncPropertyUpTask?.id),
                    ...[...updateHotelPricingSettingsResponse?.data?.updateHotelPricingSettings?.syncPropertyChannelUpTasks ?? []]
                        .map(task => taskPromise(task.id)),
                ]);
            }
            catch {
                message.error("Failed to update property pricing");
                setLoading(false);
                return;
            }

            await Promise.all(
                [...data?.property?.channels ?? []]
                    .map(channel => (
                        fetchPropertyChannel({
                            variables: {
                                propertyChannelId: channel.id,
                            },
                            fetchPolicy: 'network-only',
                        })
                    ))
            );

            message.success("Property pricing updated");
            setLoading(false);
            setModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {onboardingDone && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!onboardingDone && (
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                Setup pricing
            </Col>
            <Col>
                <Typography.Link
                    onClick={() => setModalOpen(true)}
                >
                    Setup
                </Typography.Link>
            </Col>
            <Modal
                open={modalOpen}
                title="Setup pricing"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: loading,
                }}
                onOk={() => handleSave()}
                onCancel={() => setModalOpen(false)}
                destroyOnClose
                width={700}
            >
                <Form
                    form={form}
                    preserve={false}
                    initialValues={{
                        currency: data?.property?.currency,
                    }}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                >
                    <Form.Item
                        name="currency"
                        label="Currency"
                    >
                        <CurrencySelect
                            searchable
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}